import { useEffect, useState } from 'react';
import { B64toBlob } from 'utils';
import UploadSlotControls from './UploadSlotControls/UploadSlotControls';
import { Box, Stack, Typography } from '@mui/material';
import DragAndDrop from '../DragAndDrop';
import VisorPDF from 'components/Visor/VisorPDF';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as UploadedExcel } from 'assets/DOC/Icons/UploadedExcel.svg';
import UploadedExcel from 'assets/Metlife/Icons/UploadedExcel.png';
import { UseImage } from 'components';

const MIMES_TYPES = {
  '.pdf': 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/x-zip',
  '.jpeg': `image/jpeg`,
  '.png': `image/png`,
};

const UploadSlot = ({ allowedExtensions, isExcelDocument, onSendData, isOnlyUploadSlot}) => {
  const { t } = useTranslation();
  const [fileB64, setFileB64] = useState(null);
  const [fileUrl, setfileUrl] = useState(null);
  const [extensionCurrent, setExtensionCurrent] = useState('');
console.log("Carga de documentos...............25")
  const handleFileChange = (fileB64Response, extension) => {
    if (!fileB64Response) return;
    setFileB64(fileB64Response);
    setExtensionCurrent(extension);
  };

  const handleUpload = () => {
    onSendData(fileB64, extensionCurrent);
    resetFileAndExtension();
  };

  const resetFileAndExtension = () => {
    setFileB64(null);
    setExtensionCurrent(null);
  };

  useEffect(() => {
    if (fileB64) {
      const mimeType = MIMES_TYPES[extensionCurrent];
      const blob = B64toBlob(fileB64, mimeType);
      const blobUrl = URL.createObjectURL(blob);
      setfileUrl(blobUrl);
    }
  }, [fileB64, extensionCurrent]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {fileB64 === null ? (
        <Stack sx={{ height: '100%' }}>
          <DragAndDrop
            allowedExtensions={allowedExtensions}
            isExcelDocument={isExcelDocument}
            initLoadChange={true}
            IsPhoto={false}
            onChange={handleFileChange}
          />
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            paddingTop={1}
          >
            <Box>
              <Typography variant={'span'} sx={{ color: 'lightgray' }}>
                {t('upload_doc_formats')}
              </Typography>
            </Box>
            <Box>
              <Typography variant={'span'} sx={{ color: 'lightgray' }}>
                {t('upload_doc_max_size')}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ height: 'auto'/* '100%' */, width: '100%' }} justifyContent="center" alignItems='center'>
          {allowedExtensions?.includes(extensionCurrent) && extensionCurrent === 'pdf' && (
            <VisorPDF pdfUrl={fileUrl} pdfName={'Visor PDF'} />
          )}
          {allowedExtensions?.includes(extensionCurrent) &&
            ['png', 'jpg', 'jpeg'].includes(extensionCurrent) && (
              <img
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                src={fileUrl}
                alt="experiment"
              />
            )}
          {isExcelDocument || extensionCurrent === 'zip' ? <UseImage src={UploadedExcel} style={{ height: '12rem' }} /> : null}
        </Stack>
      )}
      {fileB64 && (
        <Box sx={{ py: 2 }}>
          <UploadSlotControls
            handleUpload={handleUpload}
            resetFileAndExtension={resetFileAndExtension}
            isOnlyUploadSlot={isOnlyUploadSlot}
          />
        </Box>
      )}
    </Box>
  );
};

export default UploadSlot;
