// Mapeo de los meses
const meses = {
    "ENE": "01",
    "FEB": "02",
    "MAR": "03",
    "ABR": "04",
    "MAY": "05",
    "JUN": "06",
    "JUL": "07",
    "AGO": "08",
    "SEP": "09",
    "SEPT": "09",
    "OCT": "10",
    "NOV": "11",
    "DIC": "12"
};

const mesesCompletos = {
    "ENERO": "01",
    "FEBRERO": "02",
    "MARZO": "03",
    "ABRIL": "04",
    "MAYO": "05",
    "JUNIO": "06",
    "JULIO": "07",
    "AGOSTO": "08",
    "SEPTIEMBRE": "09",
    "OCTUBRE": "10",
    "NOVIEMBRE": "11",
    "DICIEMBRE": "12"
};

// Utilizamos una expresión regular para extraer los datos de la cadena
export const FormatDateArg = (string, type) => {
    console.log('stringdATA', string, type)
    if (type === "Contratación ML Chile Masivo" || type === "Contratación ML Chile Estándar" || type === "Contratación ML Chile Seguros") {
        const regex = /(\d{2}) (\w{3,4}) (\d{4})/;
        // /(\d{2}) (\w{3,4}) (\d{4})/;
        const match = string.match(regex);
        console.log('match', match, string)

        if (match) {
            const dia = match[1];
            const mesAbreviado = match[2]?.toUpperCase();
            const año = match[3];

            const numeroMes = meses[mesAbreviado];
            const fechaNormal = `${año}-${numeroMes}-${dia}`;
            console.log("FechaNormal:", fechaNormal, numeroMes);

            if (numeroMes) {
                console.log('REGRESA FECHA')
                return new Date(año, numeroMes - 1, dia);
            } else {
                console.log('NO EXISTE MES', numeroMes)
                return null
            }
        } else {
            console.log("El formato del string no coincide con el esperado.");
            return null;
        }

    } else {
        console.log()
        console.log('stringstring', string, 'stringstring', type);

        if (string !== undefined && string !== '' && string !== null) {
            const regex = /(\d{2}) (\w{3})\/ (\w{3}) (\d{4})/;
            const match = string.match(regex);

            if (match) {
                const dia = match[1];
                const mes1 = match[2];
                const mes2 = match[3];
                const año = match[4];

                // Obtenemos el número del mes a partir del mapeo
                const numeroMes = meses[mes1];

                // Creamos la fecha en formato YYYY-MM-DD
                const fechaNormal = `${año}-${numeroMes}-${dia}`;

                console.log("Fecha normal:", fechaNormal);
                return new Date(año, numeroMes - 1, dia);
            } else {
                console.log("El formato del string no coincide con el esperado.");
                return null;
            }
        } else { return null }
    }
}