import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChargeStatusBox,
  DocumentCard,
} from "./RequiredDocumentsSelector.styled";
import Accordion from "../../common/Accordion";
import { workFlowActions } from "store/actions";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import { createDocumentActionId } from "api/document/documentRequests";
import { CustomLoadingOverlay } from "components/CustomLoadingOverlay";

export const RequiredDocumentsSelector = ({
  handleMode,
  handleIsUploadFromStage,
  handleSetDocumentSpec,
  onRefresh,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const infoFile = useSelector((state) => state.FileInfo.items);
  const stateAuth = useSelector((state) => state.Authentication);
  const userId = stateAuth.items?.UserLoginData?.Id;
  const pendingDocumentGroups =
    infoFile?.Stage?.DocumentalCategory?.DocumentalGroups;
  const infoChargeType = useSelector(
    (state) =>
      state.FileInfo.items?.Stage?.Properties?.find(
        (item) => item.Name === "ChargeType",
      )?.Value,
  );

  const [filteredDocumentGroup, setFilteredDocumentGroup] = useState(
    pendingDocumentGroups,
  );
  const [isFiltering, setIsFiltering] = useState();
  const [selectedGroup, setSelectedGroup] = useState();

  const { isMutating, trigger } = useSWRMutation(
    "CreateActionId",
    (url, payload) => createDocumentActionId(payload),
    {
      populateCache: true,
      revalidate: false,
    },
  );

  useEffect(() => {
    setFilteredDocumentGroup(pendingDocumentGroups);
  }, [pendingDocumentGroups]);

  const handleGroupSelectionChange = (event) => {
    if (event.target.value === "noFilter") {
      setIsFiltering(false);
      return setFilteredDocumentGroup(pendingDocumentGroups);
    }
    const filteredGroup = pendingDocumentGroups.filter(
      (group) => group.Id === event.target.value.Id,
    );
    setFilteredDocumentGroup(filteredGroup);
    setIsFiltering(true);
  };

  const handleDocumentClick = async (document, documentalGroup) => {
    const DOCUMENT_SPECIFICATIONS = {
      DocumentalGroupName: documentalGroup.Name,
      DocumentType_Id: document?.Id,
      SectionId: document?.Sections[0]?.Id,
      ...document,
    };

    const PAYLOAD_ACTION_ID = {
      FileId: id,
      DocumentTypeId: document?.Id,
      UserId: userId,
      AuthToken: sessionStorage.getItem("tokenMc"),
    };

    await trigger(PAYLOAD_ACTION_ID);

    handleSetDocumentSpec(DOCUMENT_SPECIFICATIONS);
    handleIsUploadFromStage(true);
    handleMode("DOCUMENT_UPLOAD");
  };

  const handleSendButton = async () => {
    try {
      const WorkFlowInstanceId = sessionStorage.getItem("WorkFlowInstanceId");
      await dispatch(
        workFlowActions.ContinueWorkFlow({
          File_Id: id,
          WorkFlowInstanceId: WorkFlowInstanceId,
        }),
      );
      handleMode("NO_MORE_ACTIONS_PENDING");
      onRefresh();
    } catch (e) {}
  };

  const hasMissingRequiredDocuments = pendingDocumentGroups?.some(
    (documentGroup) =>
      documentGroup?.DocumentTypes?.some(
        (document) => document.Required && !document?.Document,
      ),
  );

  if (isMutating) return <CustomLoadingOverlay active={isMutating} />;

  return (
    <>
      {!infoFile ? null : (
        <Stack spacing={3}>
          <Box display="flex" gap="2rem">
            <Typography variant={"span"}>
              {t("actions_tab_upload_docs_instructions")}
            </Typography>
            <Button
              variant="contained"
              sx={{ borderRadius: "2rem", minWidth: "10rem", ml: "auto" }}
              disabled={hasMissingRequiredDocuments}
              onClick={handleSendButton}
            >
              {t("general_send")}
            </Button>
          </Box>
          <Stack spacing={2}>
            {/* <Typography>
            Los documentos marcados con <span style={{ color: 'red' }}>*</span> son obligatorios.
          </Typography> */}
            <Typography>
              <Typography>
                {t("general_documents_required_text").split("*")[0]}
                <span style={{ color: "red" }}>*</span>
                {t("general_documents_required_text").split("*")[1]}
              </Typography>
            </Typography>
            <Select
              fullWidth
              defaultValue={"noFilter"}
              onChange={handleGroupSelectionChange}
            >
              <MenuItem value={"noFilter"}>
                {t("actions_tab_upload_docs_no_filter")}
              </MenuItem>
              {pendingDocumentGroups.map((group) => (
                <MenuItem key={group.Name} value={group}>
                  {group.Name}
                </MenuItem>
              ))}
            </Select>
            {filteredDocumentGroup?.map((documentGroup, index) => (
              <Accordion
                title={
                  <Typography variant="subtitle2">
                    {documentGroup.Description}
                  </Typography>
                }
                isExpanded={isFiltering || selectedGroup === index}
                onClick={() => {
                  setSelectedGroup(index);
                }}
              >
                <Stack spacing={2}>
                  {documentGroup?.DocumentTypes?.map((document) => {
                    console.log("carga documental----: ", document);
                    const isCharged = document?.Document;
                    const isDocStatus =
                      document?.Document?.Status == "Workflow";

                    console.log("isChargerType", infoChargeType);
                    console.log("isCharged", isCharged);

                    const isDisabled =
                      isCharged &&
                      (infoChargeType === "SinCarga" ||
                        infoChargeType === "" ||
                        (isDocStatus && infoChargeType === "CargaObligatoria"));

                    const isHighlighted =
                      isCharged &&
                      (infoChargeType === "CargaOpcional" ||
                        infoChargeType === "SinCarga" ||
                        infoChargeType === "" ||
                        (isDocStatus && infoChargeType === "CargaObligatoria"));

                    console.log("isHighlighted: ", isHighlighted);
                    console.log("isDisabled: ", isDisabled);

                    return (
                      <DocumentCard
                        key={document.Id}
                        sx={{
                          backgroundColor: isHighlighted
                            ? "#FBFFF1"
                            : "initial",
                          border: isHighlighted ? "1px solid green" : "initial",
                          pointerEvents: isDisabled ? "none" : "auto",
                        }}
                        onClick={() =>
                          handleDocumentClick(document, documentGroup)
                        }
                      >
                        <Typography>
                          {document.Name}
                          {document.Required && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </Typography>
                        {((isCharged &&
                          infoChargeType !== "CargaObligatoria") ||
                          (infoChargeType === "CargaObligatoria" &&
                            isDocStatus)) && (
                          <ChargeStatusBox>
                            {t("state_uploaded")}
                          </ChargeStatusBox>
                        )}
                      </DocumentCard>
                    );
                  })}
                </Stack>
              </Accordion>
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};
