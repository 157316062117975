import { ApiUrl, ApiUrlVC } from "../utils";
import { ApiVC } from "../utils";

const Api = {
  //Complemento de url de api
  authentication: "authentication/authenticationwithrefer",
  SSOAuthenticate: "authentication/authenticationwhitmsal",
  Disabletoken: "authentication/disabletoken",
  // authentication: "authentication/authentication",
  //getFileInfo: "file/GetFileInfo",
  getFileInfo: "file/GetFileInfoByInstance",
  getUserSearch: "file/GetByUsuarioRolBusquedaPortal",
  getUserPaginationSearch: "file/GetByUsuarioRolBusquedaPortalPaginado",
  getSearch: "file/GetFilesBySearch",
  getRecord: "file/GetCustomMetadataFile",
  getActors: "file/GetActors",
  getActor: "file/GetActor",
  generateFolio: "file/GenerateFolioRemote",
  getBinnacle: "workflow/GetStageRecords",
  postFvalidation: "validation/RegisterFileValidation",
  validateDocument: "validation/RegisterDocumentValidation",
  catpchaValidation: "validation/ValidateCAPTCHA",
  validatePin: "validation/ValidatePINChangePassword",
  docExternalVal: "validation/RegisterExternalDocumentValidation",
  downloadDocument: "document/DownloadDocument",
  getDocument: "document/DownloadDocumentContent",
  downloadVersionedDocument: "document/DownloadDocumentVersioned",
  getVersionedDocument: "document/GetVersionedDocumentData",
  deleteDocument: "document/DeleteDocument",
  getStagePendingDocuments: "document/GetStagePendingDocuments",
  updateDocumentMetadata: "document/UpdateMetadata",
  getDocumentQuestions: "document/GetDocumentQuestions",
  getDocumentalStructure: "document/GetDocumentalStructure",
  getDocumentInfo: "document/GetDocumentInfo",
  updateMetadata: "document/UpdateMetadata",
  bioDocument: "document/DownloadSectionContent",
  getProduct: "product/GetProductsRemote",
  getProducts: "product/GetProducts",
  changePassword: "user/RegisterChangePassword",
  biometricData: "biometric/GetBiometricData",
  newPin: "notification/NotificationPINChangePassword",
  getRegisterGeolocationFile: "validation/GetRegisterGeolocationFile",
  getRoles: "role/GetRoles",
  getListBranchOffice: "external/GetListBranchOffice",
  getUsers: "user/GetUsers",
  getProfileData: "procedures/GetProfileData",
  registerUserRole: "user/RegisterUserRole",
  updateUserRole: "user/UpdateUserRole",
  getCustomValidationFile: "validation/GetCustomValidationFile",
  fileUpdateMetadata: "file/UpdateMetadata",
  updateMetadataEdicionMetadatos: "file/UpdateMetadataEdicionMetadatos",
  processValidationRules: "validation/ProcessValidationRules",
  continueWorkFlow: "workflow/ContinueWorkFlow",
  returnStage: "workflow/ReturnStage",
  registerStatusStage: 'workflow/RegisterStatusStage',
  getProductHiring: "product/GetProductHiring",
  // generateFolioHiring: "file/GenerateFolioHiring",
  generateFolioHiring: "file/GenerateFolio",
  uploadSection: "document/UploadSection",
  cargaDocumentoWithSection: "extraction/CargaDocumentoWithSection",
  registerMetadata: "document/RegisterExtraccionData",
  ocrData: "extraction/OcrExtractionResult",
  executeSearchCp: "ExecuteSearch/GetByCodigoPostal", //Obtener datos de catálogo CP
  asignateCurrentUser: "file/AsignateCurrentUser", //Obtener datos DE EXCEL POR B64

  getDocumentsGroupByRol: "document/GetDocumentsGroupByRol", //Obtener datos DE EXCEL POR B64

  uploadBatchFile: "flujomasivo/UploadBatchFile", //Obtener datos de EXCEL POR FORM
  uploadBatchFile2: "flujomasivo/UploadBatchFile2", //Obtener datos de EXCEL POR FORM
  initializeBatchFile: "flujomasivo/InitializeBatchFile", //Iniciar flujo masivo
  uploadBulkFile: "flujomasivo/UploadBulkFile", //Carga de archivo .zip
  getFileStage: "flujomasivo/GetFileStage", //Obtiene la etapa:
  getAllBulkFile: "flujomasivo/GetAllBulkFile", //Obtiene el listado de archivos .zip que fueron cargados

  getBulkFileDetails: "flujomasivo/GetBulkFileDetails", //Obtiene el detalle de archivos del archivo .zip cargado.
  getAllBulkMetadata: "flujomasivo/GetAllBulkMetadata", //Api que obtiene el listado de archivos cargados
  getBulkMetadataDetails: "flujomasivo/GetBulkMetadataDetails", //Api que obtiene el detalle de archivos cargados.
  uploadBulkFileMetadata: "flujomasivo/UploadBulkFileMetadata", //Api que obtiene el detalle de archivos cargados.

  getByUsuarioRolBusquedaPortalGrupos:
    "flujomasivo/GetByUsuarioRolBusquedaPortalGrupos", //Obtener datos DE EXCEL POR B64
  getByUsuarioRolBusquedaPortalDetalleGrupo:
    "flujomasivo/GetByUsuarioRolBusquedaPortalDetalleGrupo", //Obtener datos DE EXCEL POR B64
  getByUsuarioRolBusquedaPortalGruposStatus:
    "flujomasivo/GetByUsuarioRolBusquedaPortalGruposStatus", //Obtener datos DE EXCEL POR B64
  uploadBatchDocument: "flujomasivo/UploadBatchDocument", //Obtener datos DE EXCEL POR B64
  uploadBatchDocumentProcess: "flujomasivo/UploadBatchDocumentProcess", //Obtener datos DE EXCEL POR B64
  downloadDocumentContent: "flujomasivo/DownloadDocumentContent",
  uploadBatchMetadata: "flujomasivo/UploadBatchMetadata",
  uploadBatchMetadataProcess: "flujomasivo/UploadBatchMetadataProcess",

  getByUsuarioRolNotificacionesPortal:
    "notification/GetByUsuarioRolNotificacionesPortal",

    resendNip: 'notification/NotificationPINStageFile',//Reenvió de pin de autorización
    validatePIN: 'validation/VerifyPINStageFile',

  getBySelectedOption: "ExecuteSearch/GetBySelectedOption",
  getByCustomCatalog: "ExecuteSearch/GetByCustomCatalog",

  getDocumentGeneratorSelection: "document/GetDocumentGeneratorSelection",
  registerDocumentGeneratorSelection:
    "document/RegisterDocumentGeneratorSelection",
  downloadDocumentGeneratorSelectionContent:
    "document/DownloadDocumentGeneratorSelectionContent",
  DownloadDocumentViewerContent: "document/DownloadDocumentViewerContent",
  processDocumentGeneratorSelection:
    "document/ProcessDocumentGeneratorSelection",

  uploadBatchRegisterGEID: "flujomasivo/UploadBatchRegisterGEID",
  getFileEditCustomMetadata: "file/GetFileEditCustomMetadata",
  getAllUsers: "user/GetAllUsers",
  registerUserAuthenticatorRole: "user/RegisterUserAuthenticatorRole",
  updateUserAuthenticatorRole: "user/UpdateUserAuthenticatorRole",
  getTenats: "Tenant/GetTenats",
  downloadTemplateGEIDContent: "document/DownloadTemplateGEIDContent",
  facialComparison: 'document/FacialComparison',
  validateIdentityData: 'document/ValidateIdentityData',
  getValidateIdentityData: 'document/GetValidateIdentityData',
  registerDataTwo: 'document/RegisterIdentityData2',
  autographSignDocument: 'document/AutographSignDocument',

  getListGEID: "flujomasivo/GetListGEID",

  getPresentationVC: "verifier/presentation-request?SignalR=",
  authenticatorGeneraCredential: "issuer/issue-request",

  getEstatusProceso: "file/GetEstatusProceso",

  downloadFilesBySearchContent: "file/DownloadFilesBySearchContent",

  getGenericGraphics: "graph/GetGenericGraphics",

  getReports: "reports/reports",
  getReportData: "reports/report",
  getFaceDetectionIDRND: 'document/GetFaceDetectionIDRND',
  getFaceDetection: 'document/GetFaceDetection',
  getFaceFeeling: 'document/GetFaceFeeling',


  getGenericGraphics: 'graph/GetGenericGraphics',
  getReports: 'reports/reports',
  getReportData: 'reports/report',

  // 'api/issuer/issue-request'
};

export const AppUrls = {
  //Concatenación y retorno de URL según ambiente seleccionado
  // Authentication
  Authentication: `${ApiUrl.ambient}${Api.authentication}`,
  SSOAuthenticate: `${ApiUrl.ambient}${Api.SSOAuthenticate}`,
  Disabletoken: `${ApiUrl.ambient}${Api.Disabletoken}`,
  GetFileInfo: `${ApiUrl.ambient}${Api.getFileInfo}`,
  GetSearchByUser: `${ApiUrl.ambient}${Api.getUserSearch}`,
  GetUserPaginationSearch: `${ApiUrl.ambient}${Api.getUserPaginationSearch}`,
  GetInfoBySearch: `${ApiUrl.ambient}${Api.getSearch}`,
  GetRecordInfo: `${ApiUrl.ambient}${Api.getRecord}`,
  GetActorsInfo: `${ApiUrl.ambient}${Api.getActors}`,
  GetActorInfo: `${ApiUrl.ambient}${Api.getActor}`,
  GenerateFolio: `${ApiUrl.ambient}${Api.generateFolio}`,
  GetBinnacleInfo: `${ApiUrl.ambient}${Api.getBinnacle}`,
  PostFileValidation: `${ApiUrl.ambient}${Api.postFvalidation}`,
  PostDocumentValidation: `${ApiUrl.ambient}${Api.validateDocument}`,
  SendPinValidation: `${ApiUrl.ambient}${Api.validatePin}`,
  GetCatpcha: `${ApiUrl.ambient}${Api.catpchaValidation}`,
  DownloadDocument: `${ApiUrl.ambient}${Api.downloadDocument}`,
  GetDocument: `${ApiUrl.ambient}${Api.getDocument}`,
  DownloadVersionedDocument: `${ApiUrl.ambient}${Api.downloadVersionedDocument}`,
  GetVersionedDocument: `${ApiUrl.ambient}${Api.getVersionedDocument}`,
  DeleteDocument: `${ApiUrl.ambient}${Api.deleteDocument}`,
  GetStagePendingDocuments: `${ApiUrl.ambient}${Api.getStagePendingDocuments}`,
  UpdateDocumentMetadata: `${ApiUrl.ambient}${Api.updateDocumentMetadata}`,
  GetDocumentQuestions: `${ApiUrl.ambient}${Api.getDocumentQuestions}`,
  GetDocumentalStructure: `${ApiUrl.ambient}${Api.getDocumentalStructure}`,
  GetDocumentInfo: `${ApiUrl.ambient}${Api.getDocumentInfo}`,
  UpdateMetadata: `${ApiUrl.ambient}${Api.updateMetadata}`,
  ValidateDocument: `${ApiUrl.ambient}${Api.validateDocument}`,
  GetBioDocument: `${ApiUrl.ambient}${Api.bioDocument}`,
  GetProductRemote: `${ApiUrl.ambient}${Api.getProduct}`,
  GetProducts: `${ApiUrl.ambient}${Api.getProducts}`,
  ChangePassword: `${ApiUrl.ambient}${Api.changePassword}`,
  GetBiometricData: `${ApiUrl.ambient}${Api.biometricData}`,
  GetNewPin: `${ApiUrl.ambient}${Api.newPin}`,
  SendDocExternalVal: `${ApiUrl.ambient}${Api.docExternalVal}`,
  GetRegisterGeolocationFile: `${ApiUrl.ambient}${Api.getRegisterGeolocationFile}`,
  GetRoles: `${ApiUrl.ambient}${Api.getRoles}`,
  GetListBranchOffice: `${ApiUrl.ambient}${Api.getListBranchOffice}`,
  GetUsers: `${ApiUrl.ambient}${Api.getUsers}`,
  GetProfileData: `${ApiUrl.ambient}${Api.getProfileData}`,
  RegisterUserRole: `${ApiUrl.ambient}${Api.registerUserRole}`,
  UpdateUserRole: `${ApiUrl.ambient}${Api.updateUserRole}`,
  GetCustomValidationFile: `${ApiUrl.ambient}${Api.getCustomValidationFile}`,
  FileUpdateMetadata: `${ApiUrl.ambient}${Api.fileUpdateMetadata}`,
  UpdateMetadataEdicionMetadatos: `${ApiUrl.ambient}${Api.updateMetadataEdicionMetadatos}`,
  ProcessValidationRules: `${ApiUrl.ambient}${Api.processValidationRules}`,
  ContinueWorkFlow: `${ApiUrl.ambient}${Api.continueWorkFlow}`,
  ReturnStage: `${ApiUrl.ambient}${Api.returnStage}`,
  RegisterStatusStage: `${ApiUrl.ambient}${Api.registerStatusStage}`,
  GetProductHiring: `${ApiUrl.ambient}${Api.getProductHiring}`,
  GenerateFolioHiring: `${ApiUrl.ambient}${Api.generateFolioHiring}`,
  UploadSectionApi: `${ApiUrl.ambient}${Api.uploadSection}`,
  CargaDocumentoWithSection: `${ApiUrl.ambient}${Api.cargaDocumentoWithSection}`,
  RegisterMetadata: `${ApiUrl.ambient}${Api.registerMetadata}`,
  GetOcrData: `${ApiUrl.ambient}${Api.ocrData}`,
  ExecuteSearchCp: `${ApiUrl.ambient}${Api.executeSearchCp}`,
  UploadBatchFile: `${ApiUrl.ambient}${Api.uploadBatchFile}`,
  UploadBatchFile2: `${ApiUrl.ambient}${Api.uploadBatchFile2}`,
  InitializeBatchFile: `${ApiUrl.ambient}${Api.initializeBatchFile}`,
  UploadBulkFile: `${ApiUrl.ambient}${Api.uploadBulkFile}`,
  GetFileStage: `${ApiUrl.ambient}${Api.getFileStage}`,
  GetAllBulkFile: `${ApiUrl.ambient}${Api.getAllBulkFile}`,
  AsignateCurrentUser: `${ApiUrl.ambient}${Api.asignateCurrentUser}`,
  GetByUsuarioRolBusquedaPortalGrupos: `${ApiUrl.ambient}${Api.getByUsuarioRolBusquedaPortalGrupos}`,
  GetByUsuarioRolBusquedaPortalDetalleGrupo: `${ApiUrl.ambient}${Api.getByUsuarioRolBusquedaPortalDetalleGrupo}`,
  GetByUsuarioRolBusquedaPortalGruposStatus: `${ApiUrl.ambient}${Api.getByUsuarioRolBusquedaPortalGruposStatus}`,
  UploadBatchDocument: `${ApiUrl.ambient}${Api.uploadBatchDocument}`,
  UploadBatchDocumentProcess: `${ApiUrl.ambient}${Api.uploadBatchDocumentProcess}`,
  DownloadDocumentContent: `${ApiUrl.ambient}${Api.downloadDocumentContent}`,
  UploadBatchMetadata: `${ApiUrl.ambient}${Api.uploadBatchMetadata}`,
  UploadBatchMetadataProcess: `${ApiUrl.ambient}${Api.uploadBatchMetadataProcess}`,
  GetDocumentsGroupByRol: `${ApiUrl.ambient}${Api.getDocumentsGroupByRol}`,
  GetByUsuarioRolNotificacionesPortal: `${ApiUrl.ambient}${Api.getByUsuarioRolNotificacionesPortal}`,
  ResendNip: `${ApiUrl.ambient}${Api.resendNip}`,
  validatePIN: `${ApiUrl.ambient}${Api.validatePIN}`,

  GetBySelectedOption: `${ApiUrl.ambient}${Api.getBySelectedOption}`,
  GetByCustomCatalog: `${ApiUrl.ambient}${Api.getByCustomCatalog}`,

  GetDocumentGeneratorSelection: `${ApiUrl.ambient}${Api.getDocumentGeneratorSelection}`,
  RegisterDocumentGeneratorSelection: `${ApiUrl.ambient}${Api.registerDocumentGeneratorSelection}`,
  DownloadDocumentGeneratorSelectionContent: `${ApiUrl.ambient}${Api.downloadDocumentGeneratorSelectionContent}`,
  DownloadDocumentViewerContent: `${ApiUrl.ambient}${Api.DownloadDocumentViewerContent}`,
  ProcessDocumentGeneratorSelection: `${ApiUrl.ambient}${Api.processDocumentGeneratorSelection}`,

  UploadBatchRegisterGEID: `${ApiUrl.ambient}${Api.uploadBatchRegisterGEID}`,
  GetFileEditCustomMetadata: `${ApiUrl.ambient}${Api.getFileEditCustomMetadata}`,

  GetAllUsers: `${ApiUrl.ambient}${Api.getAllUsers}`,
  RegisterUserAuthenticatorRole: `${ApiUrl.ambient}${Api.registerUserAuthenticatorRole}`,
  UpdateUserAuthenticatorRole: `${ApiUrl.ambient}${Api.updateUserAuthenticatorRole}`,
  GetTenats: `${ApiUrl.ambient}${Api.getTenats}`,
  DownloadTemplateGEIDContent: `${ApiUrl.ambient}${Api.downloadTemplateGEIDContent}`,
  GetFacialComparison: `${ApiUrl.ambient}${Api.facialComparison}`,
  ValidateIdentityData: `${ApiUrl.ambient}${Api.validateIdentityData}`,
  GetValidateIdentityData: `${ApiUrl.ambient}${Api.getValidateIdentityData}`,
  RegisterDataTwo: `${ApiUrl.ambient}${Api.registerDataTwo}`,
  AutographSignDocument: `${ApiUrl.ambient}${Api.autographSignDocument}`,
  
  GetListGEID: `${ApiUrl.ambient}${Api.getListGEID}`,

  AuthenticatorGeneraCredential: `${ApiUrlVC.ambientVC}${Api.authenticatorGeneraCredential}`,
  GetApiPresentation: `${ApiUrlVC.ambientVC}${Api.getPresentationVC}`,

  GetBulkFileDetails: `${ApiUrl.ambient}${Api.getBulkFileDetails}`,
  GetAllBulkMetadata: `${ApiUrl.ambient}${Api.getAllBulkMetadata}`,
  GetBulkMetadataDetails: `${ApiUrl.ambient}${Api.getBulkMetadataDetails}`,
  UploadBulkFileMetadata: `${ApiUrl.ambient}${Api.uploadBulkFileMetadata}`,

  GetEstatusProceso: `${ApiUrl.ambient}${Api.getEstatusProceso}`,

  DownloadFilesBySearchContent: `${ApiUrl.ambient}${Api.downloadFilesBySearchContent}`,

  GetGenericGraphics: `${ApiUrl.ambient}${Api.getGenericGraphics}`,

  GetReports: `${ApiUrl.ambient}${Api.getReports}`,
  GetReportData: `${ApiUrl.ambient}${Api.getReportData}`,
  GetFaceDetectionIDRND: `${ApiUrl.ambient}${Api.getFaceDetectionIDRND}`,
  GetFaceDetection: `${ApiUrl.ambient}${Api.getFaceDetection}`,
  GetFaceFeeling: `${ApiUrl.ambient}${Api.getFaceFeeling}`,




};
