import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { b64toUrl, GetMimeType, IsDevice } from "utils";
import { documentActions } from "store/actions";
import {
  Box,
  Button,
  ButtonGroup,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { MainDocAdvancedTable } from "./MainDocAdvancedTable";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { Icon } from "@iconify/react";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
// import { createLoadingSelector } from 'store/selectors/LoadingSelectors';
import { CustomLoadingOverlay } from "components/CustomLoadingOverlay";
import useSWRMutation from "swr/mutation";
import { createDocumentActionId } from "api/document/documentRequests";
import { AlertModal } from "components/common/Modals/Views/AlertModal";
import styled from "styled-components";
import { OBIconButton } from "components/common/Buttons/IconButton/IconButton.styled";
import { MainDocAdvancedTableMobile } from "./MainDocAdvancedTableMobile";

const BtnCustom = styled(Button)(({ theme }) => ({
  borderRadius: "0px !important",
  "&:hover": {
    background: "rgba(191, 191, 191, 0.21)",
  },
}));

export const DocAdvancedViewer = ({
  handleMode,
  onClickUpdate,
  handleSetDocumentSpec,
}) => {
  const [selectedDocumentalGroup, setSelectedDocumentalGroup] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { IsMobile } = IsDevice();
  const documentalGroups = useSelector(
    (state) =>
      state.FileInfo?.items?.DocumentalCategories?.[0]?.DocumentalGroups ?? [],
  );
  const documents = documentalGroups
    ?.map((group) =>
      group.Documents.map((document) => ({
        ...document,
        grouperName: group.Name,
        grouperDescription: group.Description,
      })),
    )
    .flat();
  const filteredDocuments = selectedDocumentalGroup
    ? documents?.filter(
        (document) => document.grouperName === selectedDocumentalGroup,
      )
    : documents;
  const stateAuth = useSelector((state) => state.Authentication);
  const userId = stateAuth.items?.UserLoginData?.Id;

  const { isMutating, trigger } = useSWRMutation(
    "CreateActionId",
    (url, payload) => createDocumentActionId(payload),
    {
      populateCache: true,
      revalidate: false,
      onSuccess: () => handleMode("DOCUMENT_UPLOAD"),
    },
  );

  // const LOADING_STATES = [
  //   "GET_STAGE_PENDING_DOCUMENTS",
  //   "DELETE_DOCUMENT",
  //   "DOCUMENTS",
  // ];
  // const isLoading = useSelector(createLoadingSelector(LOADING_STATES));

  const handleSelectionChange = (event) => {
    event.target.value === " "
      ? setSelectedDocumentalGroup(null)
      : setSelectedDocumentalGroup(event.target.value);
  };

  const handleViewDocument = (row) => {
    localStorage.setItem("selectedViewDocument", JSON.stringify(row.Id));
    localStorage.setItem(
      "selectedDocumentPermissions",
      JSON.stringify(row.Permissions),
    );

    handleMode("DOCUMENT_DETAILS");
  };

  const handleDownloadDocument = async (row) => {
    // return null
    const documentB64 = await dispatch(
      documentActions.DownloadDocument(row.Id),
    );
    const blobUrl = await b64toUrl(
      `${GetMimeType(row?.Extension)},${documentB64.B64Content}`,
    );
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = row.DocumentType_Name ?? "PDF_Example.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRowUpload = async (row) => {
    const documentSpec = {
      DocumentalGroupName: row?.GrouperName,
      Document_Id: row?.Id,
      DocumentType_Id: row?.DocumentType_Id,
      SectionId: row?.Sections[0].Section_Id,
    };
    handleSetDocumentSpec(documentSpec);
    const PAYLOAD = {
      FileId: id,
      DocumentTypeId: row?.DocumentType_Id,
      UserId: userId,
      AuthToken: sessionStorage.getItem("tokenMc"),
    };
    await trigger(PAYLOAD);

    handleMode("DOCUMENT_UPLOAD");
  };

  const handleGeneralUpload = async () => {
    const pendingDocuments = await dispatch(
      documentActions.GetStagePendingDocuments(id),
    );
    localStorage.setItem(
      "StagePendingDocuments",
      JSON.stringify(pendingDocuments),
    );
    handleMode("DOCUMENT_UPLOAD_SELECTOR");
  };

  const handleDeleteButton = async (row) => {
    const response = await dispatch(documentActions.DeleteDocument(row.Id));
    if (response?.Confirmation)
      AlertModal(
        t("alert_delete_successful_title"),
        t("alert_delete_successful_message"),
        "success",
        t,
      );
    onClickUpdate();
  };

  const handleEditData = (row) => {
    localStorage.setItem("editEffectiveDate", true);
    handleViewDocument(row);
  };

  if (!documentalGroups) return null;

  return (
    <Stack spacing={5}>
      <CustomLoadingOverlay
        active={isMutating}
        text={t("general_loading_message")}
      />
      {IsMobile ? (
        <Typography variant={"h6"}>{t("request_documents")}</Typography>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          gap="1rem"
        >
          <Typography variant={"h6"}>{t("request_documents")}</Typography>
          <div style={{ display: "flex", gap: "1rem" }}>
            {/* {process.env.REACT_APP_ENVIRONMENT === "ARGDEV" && ( */}
            <Tooltip title={t("general_new_document")} placement="top">
              <OBIconButton onClick={handleGeneralUpload}>
                <FileUploadOutlinedIcon />{" "}
              </OBIconButton>
            </Tooltip>
            {/* )} */}
            <Tooltip title={t("general_refresh")} placement="top">
              <OBIconButton onClick={onClickUpdate}>
                <CachedOutlinedIcon />
              </OBIconButton>
            </Tooltip>
          </div>
        </Box>
      )}
      <Stack spacing={2}>
        {!IsMobile ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography>
                {t("documentation_table_view_instructions_1")}{" "}
                <Icon icon="solar:file-send-linear" fontSize={"1.5rem"} />{" "}
                {t("documentation_table_view_instructions_1_2")}
                <br />
                {t("documentation_table_view_instructions_2")}
              </Typography>
              <ButtonGroup
                variant="outlined"
                color={"borderBlue" /* 'borderRed' */}
              >
                <Tooltip title={t("general_table")} placement="top">
                  <BtnCustom
                    variant="outlined"
                    onClick={() => {
                      handleMode("ADVANCED_VISUALIZATION");
                      localStorage.setItem(
                        "lastDocumentViewMode",
                        "ADVANCED_VISUALIZATION",
                      );
                    }}
                  >
                    <Icon icon={"fluent:table-28-regular"} fontSize="1.5rem" />
                  </BtnCustom>
                </Tooltip>
                <Tooltip title={t("general_list")} placement="top">
                  <BtnCustom
                    variant="outlined"
                    onClick={() => {
                      handleMode("DEFAULT_DOCUMENT_VISUALIZATION");
                      localStorage.setItem(
                        "lastDocumentViewMode",
                        "DEFAULT_DOCUMENT_VISUALIZATION",
                      );
                    }}
                  >
                    <Icon icon={"material-symbols:menu"} fontSize="1.5rem" />
                  </BtnCustom>
                </Tooltip>
              </ButtonGroup>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
              <Select
                fullWidth
                defaultValue={" "}
                onChange={handleSelectionChange}
              >
                <MenuItem value={" "}>
                  {t("documentation_table_view_select_document_grouper")}
                </MenuItem>
                {documentalGroups.map((group) => (
                  <MenuItem value={group.Name}>{group.Name}</MenuItem>
                ))}
              </Select>
              <Typography
                variant={"subtitle1"}
                color={"textSecondary"}
              >{`${filteredDocuments.length} ${t("general_documents")}`}</Typography>
            </Box>
            <MainDocAdvancedTable
              tableData={filteredDocuments}
              onClickDownload={handleDownloadDocument}
              onClickView={handleViewDocument}
              onClickUpload={handleRowUpload}
              onClickDelete={handleDeleteButton}
              onClickRedirectDate={handleEditData}
            />
          </>
        ) : (
          <MainDocAdvancedTableMobile
            tableData={filteredDocuments}
            onClickView={handleViewDocument}
          />
        )}
      </Stack>
    </Stack>
  );
};
