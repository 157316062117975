import { Select, MenuItem, FormControl, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultLanguageAction } from '../store/actions/GenericActions';
import LanguageIcon from '@mui/icons-material/Language';
import { LANGUAGES } from 'mappings/LanguageMappings';
import { sendLanguageMetadata } from 'api/language/languageRequests';
import { useEffect } from 'react';

export const SelectLanguage = ({ hidden = false, isMobileView }) => {
  const stateAuth = useSelector((state) => state.Authentication);
  const langCode = useSelector(state => state.SetDefualtLanguage?.lang)
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage(langCode || 'en');
    localStorage.setItem('lang', langCode);
  }, []);

  const onChangeLang = async (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
    localStorage.setItem('lang', lang_code);
    if (stateAuth.items && !isMobileView)
      await sendLanguageMetadata([
        {
          GroupName: 'System Language',
          Name: 'Code',
          Value: lang_code,
        },
      ]);
    dispatch(setDefaultLanguageAction(lang_code));
  };

  if (hidden) return <div />;

  return (
    <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', gap: '0.2rem' }}>
      <Select
        label={t('general_language')}
        value={langCode}
        onChange={onChangeLang}
        renderValue={(value) => (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <LanguageIcon />
            {isMobileView ? null : LANGUAGES.find((language) => language.code === value)?.label}
          </Box>
        )}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          width: '100%',
          border: 'none',
          padding: '0px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiOutlinedInput-input': {
            padding: '0px',
          },
        }}
      >
        {LANGUAGES.map(({ code, label }) => (
          <MenuItem value={code}>{label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
