import { Typography, Grid, Tooltip } from "@mui/material";
import { DocImageMappings } from './DocImageMappings';
import { Card } from './requestDocumentationViewer.styled';
import { UseImage } from 'components/UseImage';
import { Icon } from '@iconify/react';
import { Box } from '@mui/system';
import { useTranslation } from "react-i18next";

const getApproveStatusIcon = (status) => {
  if (status === true) return <Icon icon={'icon-park-solid:check-one'} fontSize={'2rem'} color="#0fa958" />;
  if (status === false) return <Icon icon={'ic:sharp-cancel'} fontSize={'2.2rem'} color="red" />;
  return <Icon icon={'akar-icons:circle-fill'} fontSize={'2rem'} color="#ffd233"/>;
};

const getApproveTooltipLabel = (status) => {
  if (status === true) return "approve_status_tooltip_approved";
  if (status === false) return "approve_status_tooltip_rejected";
  return "approve_status_tooltip_pending";
};

export const DocCard = ({ docInfo, onCardClick }) => {
  const { t } = useTranslation();
  const imageDoc = DocImageMappings[docInfo.tipoDocumentoDesc] || DocImageMappings['default'];
  const validationStatus = docInfo?.Validation?.Approved;

  return (
    <Card sx={{ width: '13rem', borderRadius:'1rem', border:'1px solid #D2D2D2', boxShadow:3, position:'relative'}} onClick={onCardClick}>
      <Box sx={{position: 'absolute', top: '1rem', right: '1rem'}}>
        <Tooltip title={t(getApproveTooltipLabel(validationStatus))} placement={'top'} arrow>
          {getApproveStatusIcon(validationStatus)}
        </Tooltip>
      </Box>
      <Grid container sx={{ maxWidth: '90%' }} spacing={'2rem'}>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <UseImage src={imageDoc} type="img" />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}
          >
            {docInfo.Name}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
