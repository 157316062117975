import { useSelector } from "react-redux";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import Stack from "@mui/material/Stack";
import { DocCard } from "./DocCard";
import Accordion from "components/common/Accordion/index";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { OBIconButton } from "components/common/Buttons/IconButton/IconButton.styled";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const BtnCustom = styled(Button)(({ theme }) => ({
  borderRadius: "0px !important",
  "&:hover": {
    background: "rgba(191, 191, 191, 0.21)",
  },
}));

export const DocBasicView = ({ handleMode, onClickUpdate }) => {
  const { t } = useTranslation();
  const documentalGroups = useSelector(
    (state) =>
      state.FileInfo?.items?.DocumentalCategories?.[0]?.DocumentalGroups ?? [],
  );
  // const stateAuth = useSelector((state) => state.Authentication);
  // const userId = stateAuth.items?.UserLoginData?.Id;
  const hasNoValidation = (item) =>
    item?.Validation?.Approved === undefined ||
    item?.Validation?.Approved === null;
  const isValid = (item) => item?.Validation?.Approved === true;
  const isInvalid = (item) => item?.Validation?.Approved === false;

  const validationIcon = (documents) => {
    console.log(documents);
    if (documents.some(hasNoValidation)) return null;
    if (documents.some(isValid))
      return <CheckCircleIcon style={{ color: "#28A745" }} />;
    if (documents.some(isInvalid))
      return <CancelIcon style={{ color: "red" }} />;
    return "";
  };

  // const PAYLOAD = {
  //   User_Id: userId,
  //   DocumentalGroup_Id: null,
  //   AllByTenant: false,
  // };

  const { data: documentPermissions } = useSWR("PermissionsByRol");

  const handleCardClick = (doc, group) => {
    const permissions = documentPermissions?.PermissionGroups?.find(
      (documentPermission) =>
        documentPermission.DocumentalGroupName === group.Name,
    )?.Permissions;
    localStorage.setItem(
      "selectedDocumentPermissions",
      JSON.stringify(permissions),
    );
    localStorage.setItem("selectedViewDocument", JSON.stringify(doc.Id));
    handleMode("DOCUMENT_DETAILS");
  };

  if (!documentalGroups) return null;

  return (
    <Stack spacing={5}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant={"h6"}>{t("request_documents")}</Typography>
        <Tooltip title={t("general_refresh")} placement="top">
          <OBIconButton onClick={onClickUpdate}>
            <CachedOutlinedIcon />
          </OBIconButton>
        </Tooltip>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
      >
        <Typography>{t("documentation_list_view_instructions")}</Typography>
        <ButtonGroup variant="outlined" color={"borderBlue" /* 'borderRed' */}>
          <Tooltip title={t("general_table")} placement="top">
            <BtnCustom
              variant="outlined"
              onClick={() => {
                handleMode("ADVANCED_VISUALIZATION");
                localStorage.setItem(
                  "lastDocumentViewMode",
                  "ADVANCED_VISUALIZATION",
                );
              }}
            >
              <Icon icon={"fluent:table-28-regular"} fontSize="1.5rem" />
            </BtnCustom>
          </Tooltip>
          <Tooltip title={t("general_list")} placement="top">
            <BtnCustom
              variant="outlined"
              onClick={() => {
                handleMode("DEFAULT_DOCUMENT_VISUALIZATION");
                localStorage.setItem(
                  "lastDocumentViewMode",
                  "DEFAULT_DOCUMENT_VISUALIZATION",
                );
              }}
            >
              <Icon icon={"material-symbols:menu"} fontSize="1.5rem" />
            </BtnCustom>
          </Tooltip>
        </ButtonGroup>
      </Box>

      <Stack spacing={2}>
        {documentalGroups.map((group) => (
          <Accordion
            title={
              <>
                <Typography variant="subtitle2">
                  {group.TranslatedText || group.Description}
                </Typography>
              </>
            }
          >
            <Grid container spacing={3} padding={"2rem"}>
              {group.Documents.map((doc) => (
                <Grid item xs="auto">
                  <DocCard
                    docInfo={doc}
                    key={doc.Id}
                    onCardClick={() => handleCardClick(doc, group)}
                  />
                </Grid>
              ))}
            </Grid>
          </Accordion>
        ))}
      </Stack>
    </Stack>
  );
};
