import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DocumentMultiLoader from 'components/document/DocumentMultiLoader';
import DocumentMetadata from 'components/document/DocumentMetadata';
import { DocBasicView } from '../../document/DocBasicView';
import { DocAdvancedViewer } from '../../document/DocAdvancedView';
import { DocumentDetails } from '../../document/DocumentDetails';
import { DocumentUploadSelector } from '../../document/DocumentUploadSelector';
import { DocumentMetadataUpdate } from '../../document/DocumentMetadataUpdate';
import useSWR from 'swr';
import { AlertModal } from '../../common/Modals/Views/AlertModal';
import { useTranslation } from 'react-i18next';
import { getPermissions } from "api/permission/permissionRequests";
import { IsDevice } from 'utils';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';

export const DocumentationTabs = ({ OnLoading }) => {
  const {id:fileId} = useParams()
  const {IsMobile} = IsDevice()
  const [mode, setMode] = useState(
    localStorage.getItem('lastDocumentViewMode') || (IsMobile ? "ADVANCED_VISUALIZATION" : "DEFAULT_DOCUMENT_VISUALIZATION"),
  );
  const [documentSpecifications, setDocumentSpecifications] = useState();
  const [documentB64, setDocumentB64] = useState();
  const [keepRevalidating, setKeepRevalidating] = useState(true);

  /* const dispatch = useDispatch();
  const { id } = useParams();
  const workFlowInstanceId = sessionStorage.getItem('WorkFlowInstanceId'); */
  const { data: actionIdData } = useSWR('CreateActionId');
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const getInfoDispatch = () => {
    OnLoading()
    /* dispatch(
      fileActions.GetFileInfo({
        FileId: id,
        WorkFlowInstanceId: workFlowInstanceId,
      })
    ); */
  };

  useEffect(() => {
    const initialView = sessionStorage.getItem('documentationTabInitialView');
    initialView && setMode(initialView);
    sessionStorage.removeItem('documentationTabInitialView');
  }, []);

  const handleUploadFileSuccess = () => {
    AlertModal('', t('documentation_upload_doc_successful_upload'), 'success').then(() => {
      handleMode(localStorage.getItem('lastDocumentViewMode') || 'ADVANCED_VISUALIZATION');
    });
  };

  const handleSetDocumentSpec = (spec) => {
    // console.log('SPEC: ',spec)
    setDocumentSpecifications(spec);
  };
  const handleSetDocumentB64 = (documentB64) => {
    setDocumentB64(documentB64);
  };

  const handleMode = (mode) => {
    setMode(mode);
    ["DEFAULT_DOCUMENT_VISUALIZATION", "ADVANCED_VISUALIZATION"].includes(mode) &&
      getInfoDispatch();
  };

  //abrir documento si hubo una redireccion con elasticSearch
  // const stateAuth = useSelector((state) => state.Authentication);
  // const userId = stateAuth.items?.UserLoginData?.Id;

  const PAYLOAD = {
    File_Id: fileId,
  };

  const [docLoaded, setDocLoaded] = useState(false);
  const documentalGroups = useSelector(
    (state) =>
      state.FileInfo?.items?.DocumentalCategories?.[0]?.DocumentalGroups ?? []
  );
  const queryParams = new URLSearchParams(location.search);
  const docId = queryParams.get("docId");
  const { data: documentPermissions, isLoading } = useSWR(
    "PermissionsByRol",
    () => getPermissions(PAYLOAD),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const handleViewDocument = React.useCallback((docID) => {
    const documents = documentalGroups
      ?.map((group) =>
        group.Documents.map((document) => ({
          ...document,
          grouperName: group.Name,
          grouperDescription: group.TranslatedText || group.Description,
        }))
      )
      .flat();

    if (docID && documents.length > 0) {
      const docFound = documents.find((doc) => doc.Id === docID); // Busca el documento
      if (docFound) {
        const permissions = documentPermissions?.PermissionGroups?.find(
          (documentPermission) =>
            documentPermission.DocumentalGroupName === docFound.grouperName
        )?.Permissions;

        localStorage.setItem(
          "selectedViewDocument",
          JSON.stringify(docFound.Id)
        );
        localStorage.setItem(
          "selectedDocumentPermissions",
          JSON.stringify(permissions)
        );
        setMode("DOCUMENT_DETAILS");
      }
    }
  }, [documentPermissions?.PermissionGroups, documentalGroups]);

  useEffect(() => {
    if (
      docId !== undefined &&
      docId !== null &&
      documentalGroups.length > 0 &&
      !docLoaded
    ) {
      setDocLoaded(true);
      handleViewDocument(parseInt(docId));
    }
  }, [docId, documentalGroups, docLoaded, handleViewDocument]);

  useEffect(() => {
    if (docLoaded) {
      setDocLoaded(false);
      navigate(location.pathname, { replace: true });
    }
  }, [location.pathname, navigate, docLoaded]);

  const stopRevalidating = () => setKeepRevalidating(false)

  const RENDER_COMPONENT_BY_MODE = {
    DEFAULT_DOCUMENT_VISUALIZATION: (
      <DocBasicView handleMode={handleMode} onClickUpdate={getInfoDispatch} />
    ),
    ADVANCED_VISUALIZATION: (
      <DocAdvancedViewer
        handleMode={handleMode}
        onClickUpdate={getInfoDispatch}
        handleSetDocumentSpec={handleSetDocumentSpec}
      />
    ),
    DOCUMENT_DETAILS: (
      <DocumentDetails
        handleMode={handleMode}
        handleSetDocumentSpec={handleSetDocumentSpec}
      />
    ),

    DOCUMENT_UPLOAD_SELECTOR: (
      <DocumentUploadSelector
        handleMode={handleMode}
        handleSetDocumentSpec={handleSetDocumentSpec}
      />
    ),
    DOCUMENT_UPLOAD: (
      <DocumentMultiLoader
        handleMode={handleMode}
        handleSetDocumentB64={handleSetDocumentB64}
        keepRevalidating={keepRevalidating}
      />
    ),
    DOCUMENT_METADATA: (
      <DocumentMetadata
        handleMode={handleMode}
        documentB64={documentB64}
        documentSpec={documentSpecifications}
        actionId={actionIdData?.Result}
        handleUploadFileSuccess={handleUploadFileSuccess}
        stopRevalidating={stopRevalidating}
      />
    ),
    DOCUMENT_METADATA_UPDATE: (
      <DocumentMetadataUpdate
        handleMode={handleMode}
        documentSpec={documentSpecifications}
      />
    ),
  };

  console.log("mode: ", mode)
  return <>
        <CustomLoadingOverlay
        active={isLoading}
        text={t("general_loading_message")}
      />
      {RENDER_COMPONENT_BY_MODE[mode]}
      </>;
};
