import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UseImage, InputField, Alert } from "../../components";
import { FormProvider, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate, useLocation } from "react-router-dom";

import iconMeet from "../../assets/iconoMeet.png";
import { UploadDcumentsControlMultiple } from "../../components/DocumentUpload/UploadDcumentsControlMultiple";
import { useDispatch, useSelector } from "react-redux";
import { flujoMasivoActions } from "../../store/actions";
import TableDataDocumentsFilters from "./TableDataDocuments";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  loader: {
    position: "absolute !important",
    top: "0 !important",
    bottom: "0 !important",
    left: "0 !important",
    right: "0 !important",
    height: "100vh !important",
    width: "100% !important",
    zIndex: 100000,
  },
}));

export default function UploadDocumentsMasive(props) {
  const theme = useTheme();
  const { control, watch, formState, setValue, register, setError } = useForm({
    mode: "onBlur",
  });
  const dataStages = useSelector((state) => state.GetFileStageData);
  const uploadBulkFileData = useSelector((state) => state.UploadBulkFileData);
  const infoDocumentsZip = useSelector((state) => state.GetBulkFileDetails);
  const dataInfoStage = useSelector((state) => state.Authentication);
  const formMethods = useForm({ mode: "onChange" });
  const classes = useStyles(theme);

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const [documentsUpload, setDocumentsUpload] = useState([]);
  const [dataStagesList, setDataStagesList] = useState([]);
  const [activeCargaMasiva, setActiveCargaMasiva] = useState(false);
  const [loadView, setLoadView] = useState(null);

  const etapa = watch("Etapa");
  const descriptionData = watch("description");

  const { t } = useTranslation();

  //   console.log("dataInfoStage", dataInfoStage);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64WithoutHeader = reader.result.split(",")[1];
        resolve(base64WithoutHeader);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFileInputChange = (FileContent) => {
    if (!!FileContent) {
      if (descriptionData == "" || etapa == "") {
        setError("Etapa", {
          type: "required",
          message: t("select_stage"),
        });
        setError("description", {
          type: "required",
          message: t("write_description"),
        });
      } else {
        console.log("etapa", etapa, descriptionData);

        if (!!FileContent) {
          const selectedFile = FileContent[0];
          getBase64(selectedFile)
            .then((result) => {
              if (result) {
                console.log(
                  "location WorkFlowId : ",
                  location.state?.WorkFlowId,
                  " - ",
                  location,
                );

                const document = FileContent[0]?.name?.split(".");
                console.log("result", result);
                dispatch(
                  flujoMasivoActions.UploadBulkFile({
                    FileContentB64: result,
                    StageId: etapa?.Option,
                    Extension: document[1],
                    FileName: document[0],
                    Description: descriptionData,
                    WorkflowId: location.state?.WorkFlowId,
                  }),
                );
                setActiveCargaMasiva(true);
                setLoadView(true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  };

  useEffect(() => {
    // console.log(
    //   "uploadBulkFileData.items?.error ",
    //   uploadBulkFileData.items?.error,
    //   uploadBulkFileData.error,
    // );
    if (
      uploadBulkFileData?.error != undefined &&
      uploadBulkFileData?.error != null &&
      activeCargaMasiva
    ) {
      setActiveCargaMasiva(false);
      setLoadView(false);
      Alert(
        {
          icon: 0,
          title: uploadBulkFileData?.error,
        },
        null,
        null,
        t,
      );
    } else if (!!uploadBulkFileData.items?.Body) {
      //   console.log("documentsUpload 0", uploadBulkFileData);
      setLoadView(false);
      setDocumentsUpload(uploadBulkFileData.items.Body);
    } else if (!!infoDocumentsZip.items?.Body) {
      //   console.log("documentsUpload 1", infoDocumentsZip);
      setLoadView(false);
      setDocumentsUpload(infoDocumentsZip.items.Body);
    } else if (uploadBulkFileData.items == undefined) {
      setLoadView(false);
      setDocumentsUpload([]);
    } else if (infoDocumentsZip.items == undefined) {
      setLoadView(false);
      setDocumentsUpload([]);
    }
  }, [uploadBulkFileData, infoDocumentsZip, infoDocumentsZip.items]);

  useEffect(() => {
    if (!!dataStages.items) {
      let newData = dataStages.items.Body.map((item) => ({
        Value: item.StageId,
        Option: item.Description,
      }));
      //   console.log("newDatanewData", newData);
      setDataStagesList(newData);
    }
  }, [dataStages.items]);
  //   console.log("dataStages", dataStages);

  const resetData = () => {
    dispatch(flujoMasivoActions.UploadBulkFile(null, true));
    dispatch(flujoMasivoActions.GetBulkFileDetails(null, true));
    setDocumentsUpload([]);
    navigation("/inbox/bulkDocuments");
  };

  const reloadDataDocument = () => {
    dispatch(flujoMasivoActions.UploadBulkFile(null, true));
    dispatch(
      flujoMasivoActions.GetBulkFileDetails({
        BatchUploadDocumentId: documentsUpload[0]?.BatchUploadDocument_Id,
      }),
    );
    setValue("Etapa", "");
    setValue("description", "");
    setDocumentsUpload([]);
  };

  const productosAplican = [
    "Contratación ML Chile Masivo",
    "Contratación ML Chile Estándar",
    "Contratación ML Chile Seguros",
    "Contratación ML Chile Provida",
  ];

  const nomenclatura =
    location.state?.WorkFlowId == 6 || location.state?.WorkFlowId == 4
      ? "folio_"
      : "identificadornacional_";

  return (
    <>
      {loadView && (
        <LoadingOverlay
          active={loadView}
          spinner
          text={t("general_loading_message")}
          className={classes.loader}
        ></LoadingOverlay>
      )}
      {documentsUpload?.length > 0 ? (
        <>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12} xs={12}>
              <TableDataDocumentsFilters
                data={documentsUpload}
                CleanData={() => resetData()}
                ReloadData={() => reloadDataDocument()}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item lg={10} xs={12}>
            {/* {console.log("dataStagesList'''''''''FORM Render.............: ",dataStagesList?.length , dataStagesList)} */}
            {dataStagesList?.length > 0 ? (
              <Box>
                <InputField
                  inputType="autocomplete"
                  control={control}
                  register={register}
                  name={"Etapa"}
                  label={t("upload_doc_field_stage")}
                  optionsList={dataStagesList}
                  validations={{ required: true }}
                  error={!!formState.errors[`Etapa`]}
                  helperText={formState.errors[`Etapa`]?.message}
                  required={true}
                />
              </Box>
            ) : null}
            <Box>
              <InputField
                inputType="text"
                control={control}
                register={register}
                name={"description"}
                label={t("upload_doc_field_description")}
                validations={{ required: true }}
                defaultValue={""}
                error={!!formState.errors["description"]}
                helperText={formState.errors["description"]?.message}
                required={true}
              />
            </Box>
          </Grid>
          <Grid item lg={10} xs={12}>
            <Box textAlign={"justify"} display="flex" alignItems={"center"}>
              <Typography variant="subtitle">{t("only_upload_zip")}</Typography>
            </Box>
            <Box textAlign={"justify"} display="flex" alignItems={"center"}>
              <Typography variant="subtitle">
                {t("upload_doc_description_1")}
              </Typography>
            </Box>
            {location.state?.WorkFlowId == 12 &&
            location.state?.WorkFlowName != "Contratación PROVIDA SPEN" ? (
              <>
                <Box textAlign={"justify"} display="flex" alignItems={"center"}>
                  <UseImage
                    src={iconMeet}
                    type="img"
                    style={{ margin: "1em" }}
                  />
                  <Typography variant="subtitle">
                    Datanomics: <b>{nomenclatura}OFAC.pdf</b>
                  </Typography>
                </Box>
                <Box textAlign={"justify"} display="flex" alignItems={"center"}>
                  <UseImage
                    src={iconMeet}
                    type="img"
                    style={{ margin: "1em" }}
                  />
                  <Typography variant="subtitle">
                    Datanomics: <b>{nomenclatura}CBI.pdf</b>
                  </Typography>
                </Box>
                <Box textAlign={"justify"} display="flex" alignItems={"center"}>
                  <UseImage
                    src={iconMeet}
                    type="img"
                    style={{ margin: "1em" }}
                  />
                  <Typography variant="subtitle">
                    Datanomics: <b>{nomenclatura}PEP.pdf</b>
                  </Typography>
                </Box>
                <Box textAlign={"justify"} display="flex" alignItems={"center"}>
                  <UseImage
                    src={iconMeet}
                    type="img"
                    style={{ margin: "1em" }}
                  />
                  <Typography variant="subtitle">
                    Datanomics: <b>{nomenclatura}SERASA.pdf</b>
                  </Typography>
                </Box>
              </>
            ) : (
              <Box textAlign={"justify"} display="flex" alignItems={"center"}>
                <UseImage src={iconMeet} type="img" style={{ margin: "1em" }} />
                <Typography variant="subtitle">
                  Datanomics:{" "}
                  <b>
                    {nomenclatura}
                    {location.state?.WorkFlowId == 4
                      ? "datanomicsestandar"
                      : location.state?.WorkFlowId == 6
                        ? "DatanomicsMasivo" //DatanomicsMasivo
                        : location.state?.WorkFlowId == 10
                          ? "Evidencia Hallazgos Datanomics"
                          : "Datanomics"}
                    .pdf
                  </b>
                </Typography>
              </Box>
            )}
            {/* {console.log(
              "location WorkFlowId----respomse render : ",
              location.state?.WorkFlowId,
              " - ",
              location,
            )} */}
            {location.state?.WorkFlowId == 2 ||
            location.state?.WorkFlowId == 3 ||
            location.state?.WorkFlowId == 8 ||
            location.state?.WorkFlowId == 5 ||
            (location.state?.WorkFlowId == 12 &&
              location.state?.WorkFlowName ==
                "Contratación PROVIDA SPEN") ? null : (
              <Box textAlign={"justify"} display="flex" alignItems={"center"}>
                <UseImage src={iconMeet} type="img" style={{ margin: "1em" }} />
                <Typography variant="subtitle">
                  {t("medical_exam")}:{" "}
                  <b>
                    {nomenclatura}
                    {location.state?.WorkFlowId == 4
                      ? "examenmedico"
                      : location.state?.WorkFlowId == 6
                        ? "ExamenMedicoMasivo"
                        : location.state?.WorkFlowId == 12
                          ? "Cita Exame Médico"
                          : "ExamenMedico"}
                    .pdf{" "}
                  </b>
                </Typography>
              </Box>
            )}
            {
              /* location.state?.WorkFlowId == 4 || location.state?.WorkFlowId == 6 || */ location
                .state?.WorkFlowId == 2 ? (
                <Box textAlign={"justify"} display="flex" alignItems={"center"}>
                  <UseImage
                    src={iconMeet}
                    type="img"
                    style={{ margin: "1em" }}
                  />
                  <Typography variant="subtitle">
                    {t("upload_doc_socioecon_study")}{" "}
                    <b>
                      {nomenclatura}
                      {location.state?.WorkFlowId == 4
                        ? "EstudioSocioeconomicoEstandar"
                        : "EstudioSocioeconomico"}
                      .pdf
                    </b>
                  </Typography>
                </Box>
              ) : null
            }
            {location.state?.WorkFlowId == 6 ||
            location.state?.WorkFlowId == 4 ? (
              <Box textAlign={"justify"} display="flex" alignItems={"center"}>
                <UseImage src={iconMeet} type="img" style={{ margin: "1em" }} />
                <Typography variant="subtitle">
                  {t("upload_doc_security_study")}{" "}
                  <b>
                    {nomenclatura}
                    {location.state?.WorkFlowId == 6
                      ? "EstudioSocioeconomicoEstandar"
                      : location.state?.WorkFlowId == 4
                        ? "EstudioSocioeconomicoMasivo"
                        : "EstudioSocioeconomico"}
                    .pdf
                  </b>
                </Typography>
              </Box>
            ) : null}
            {location.state?.WorkFlowId == 3 ? (
              <Box textAlign={"justify"} display="flex" alignItems={"center"}>
                <UseImage src={iconMeet} type="img" style={{ margin: "1em" }} />
                <Typography variant="subtitle">
                  PDA: <b>{nomenclatura}PDA.pdf</b>
                </Typography>
              </Box>
            ) : null}
          </Grid>
          <Grid item lg={10} xs={12}>
            <FormProvider {...formMethods}>
              <UploadDcumentsControlMultiple
                Compressed={true}
                Title={t("general_upload_your_file_zip")}
                onChange={(e) => handleFileInputChange(e)}
              />
              {/* <div>
                                    <input type="file" name="file" onChange={(e) => handleFileInputChange(e)} />
                                </div> */}
            </FormProvider>
          </Grid>
          <Grid lg={5} xs={12}>
            <Box display={"flex"} justifyContent="center">
              <Button
                style={{
                  height: 38,
                  width: "100%",
                  margin: "1em",
                  borderRadius: "25px",
                  fontSize: 16,
                  fontWeight: 500,
                }}
                variant="outlined"
                color="primary"
                onClick={() => navigation("/inbox/bulkDocuments")}
              >
                {t("general_cancel")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
