import { useEffect, useState } from 'react';
import { AppBar, Box, IconButton, Typography, styled } from '@mui/material';
import { Icon } from '@iconify/react';
import MobileUploadSelector from './MobileUploaderSelector';
import MobileUploaderRequiredDocuments from './MobileUploaderRequiredDocuments/MobileUploaderRequiredDocuments';
import MobileUploaderDrag from './MobileUploaderDrag/MobileUploaderDrag';
import { CustomLoadingOverlay, FullCameraCapture, SelectLanguage } from 'components';
import { useValidateDocument } from 'hooks/document/useValidateDocument';
import DocumentMetadata from 'components/document/DocumentMetadata';
import { MobileSuccessUpload } from './MobileSuccessUpload/MobileSuccessUpload';
import useSWR from 'swr';

const CustomBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: '',
  gap: '4rem',
  alignItems: 'center',
  width: '100%',
  minHeight: 'calc(90% - 120px)',
  textAlign: 'center',
  padding: '4rem 2rem 2rem 2rem',
}));

const MobileUploader = () => {
  const { data: documentStructure } = useSWR('GetDocumentalStructure');
  const queryParams = new URLSearchParams(window.location.search);
  const actionId = queryParams.get('GUID');
  const [initCamera, setInitCamera] = useState(false);
  const [mode, setMode] = useState('DEFAULT');
  const [documentB64, setDocumentB64] = useState();
  const [keepRevalidating, setKeepRevalidating] = useState(true);
  const handleMode = (value) => setMode(value);
  const { Description, isValidatingActionId, isLoadingDocumentStructure, fileId, docTypeId } =
    useValidateDocument(actionId, keepRevalidating);
  const isLoading = isValidatingActionId || isLoadingDocumentStructure;
  const documentSpec = {
    DocumentType_Id: documentStructure?.DocumentType_Id,
    SectionId: documentStructure?.Sections[0]?.Section_Id,
    ActionId: actionId,
  };
  const isBrasilEnvironment = process.env.REACT_APP_ENVIRONMENT === "BRADEV" || process.env.REACT_APP_ENVIRONMENT === "BRA"

  useEffect(() => {
    if(fileId && docTypeId){
      const queryParams = new URLSearchParams(window.location.search);
      window.history.pushState({}, '', `${window.location.pathname}?${queryParams.toString()}`);
    }
  }, [fileId, docTypeId])
  

  const handleUploadFileSuccess = () => handleMode('SUCCESS_UPLOADMENT');

  const handleSetDocumentB64 = (file) => {
    setInitCamera(false);
    setDocumentB64(file);
    setMode('DOCUMENT_METADATA');
  };

  const handleCaptureMethod = (value) => {
    if (value === 'CAMERA') {
      setInitCamera(true);
      return;
    }
    setMode(value);
  };

  const handleReturnButton = () => setMode('SELECTOR');

  const stopRevalidating = () => setKeepRevalidating(false)

  const RENDER_SECTION = {
    DEFAULT: (
      <MobileUploaderRequiredDocuments handleContinue={handleMode} requiredDocument={Description} />
    ),
    SELECTOR: (
      <MobileUploadSelector
        handleCaptureMethod={handleCaptureMethod}
        requiredDocument={Description}
      />
    ),
    DRAG: (
      <MobileUploaderDrag
        handleCaptureUploadDocument={handleSetDocumentB64}
        requiredDocument={Description}
      />
    ),
    DOCUMENT_METADATA: (
      <DocumentMetadata
        documentB64={documentB64}
        documentSpec={documentSpec}
        actionId={actionId}
        handleUploadFileSuccess={handleUploadFileSuccess}
        handleBack={handleReturnButton}
        isMobileView
        stopRevalidating={stopRevalidating}
      />
    ),
    SUCCESS_UPLOADMENT: <MobileSuccessUpload />,
  };

  if (isLoading) return <CustomLoadingOverlay active={isLoading} />;

  return (
    <Box sx={{ display: 'grid', placeItems: 'center', height: '100vh', width: '100%' }}>
      <Box sx={{ width: '100%', maxWidth: '550px', height: '100%' }} boxShadow={3}>
        <AppBar
          color="default"
          sx={{
            display: 'grid',
            placeItems: 'center',
            height: '120px',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          <Box
            sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', alignItems: 'center' }}
          >
            {mode !== 'DEFAULT' && mode !== 'SUCCESS_UPLOADMENT' ? (
              <IconButton
                onClick={() => setMode('DEFAULT')}
                sx={{ position: "absolute", left: { xs: ".5rem", md: "2rem" } }}
              >
                <Icon icon={'mdi:arrow-back'} />{' '}
              </IconButton>
            ) : null}
            <Typography
              variant="h4"
              sx={{
                height: "100%",
                maxWidth: {
                  xs: "calc(450px - 11rem)",
                  md: "calc(550px - 11rem)",
                },
              }}
            >
              {Description}
            </Typography>
          </Box>
          <Box sx={{ position: "absolute", right: {xs: 0, md: "2rem"} }}>
          {!isBrasilEnvironment ? (
              <SelectLanguage isMobileView />
            ) : null}
          </Box>
        </AppBar>
        <CustomBox>{RENDER_SECTION[mode]}</CustomBox>
      </Box>
      <FullCameraCapture
        open={initCamera}
        onClose={() => setInitCamera(false)}
        onSendData={(fileb64, extension) => handleSetDocumentB64({ fileb64, extension })}
      />
    </Box>
  );
};

export default MobileUploader;
