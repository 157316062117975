import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
    Typography,
    Dialog,
    Button,
    Grid,
    Box,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Paper,
    TextField,
    OutlinedInput,
    MenuItem,
    styled, useTheme
} from "@mui/material";
import {
    Controller,
    useForm,
    useFormContext,
    FormProvider,
} from "react-hook-form";
import { makeStyles } from '@mui/styles'

import {
    CustomLoadingOverlay
} from "../../components";
import VisorPDF from "../../components/Visor/VisorPDF.js";

import { mainStyles } from '../../theme/mainStyles'
import { executeSearchActions } from '../../store/actions'
import { useTranslation } from "react-i18next";

const ContainerVisorCustom = styled(Grid)(({ theme }) => ({
    display: 'grid !important',
    gridTemplateColumns: '50% 50%',
    // gridAutoRows: '100vh !important',
    overflowY: 'auto !important',
    '&::-webkit-scrollbar': {
        width: '0',
        background: 'transparent',
    },
    // minHeight: "100vh",
    overflowX: 'hidden',
    width: '100%',
    // border: '1px solid red !important',
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '100%',
    },
    [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '100%',
    },
    [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '50% 50%',
    },
}));

const PdfContainerIOS = styled(Box)(({ theme }) => ({
    width: "100%",
    margin: "10px auto",
    border: '1px solid gray',
    '@media only screen and (max-width: 620px) and (min-width: 5px)': {
        height: '52vh',
    },
    overflow: "auto",
    position: "relative",
    "& .react-pdf__Document": {
        backgroundColor: "#888",
        '@media only screen and (max-width: 620px) and (min-width: 5px)': {
            height: '52vh',
        },
        display: "flex",
        flexDirection: "column",
        border: "0.5px solid gray",
    },
    "& .react-pdf__Page": {
        flexGrow: 1,
        display: "block",
        padding: "8px 8px",
    },
    "& .react-pdf__Page__annotations": {
        display: "none",
    },
    "& canvas": {
        padding: "10px 0px",
        margin: "0 auto",
    },
    [theme.breakpoints.down("sm")]: {
        "& .react-pdf__Page": {
            padding: "5px 5px",
        },
        "& canvas": {
        },
    },
}));

export const SeeOCRExtraction = (props) => {
    const gridContainer = mainStyles.gridStyle();
    const localStyles =  useClasses();

    console.log("SeeOCRExtraction props*********************: ", props)
    const [documentInfo, setdocumentInfo] = useState([]);
    const [openEditableForm, setopenEditableForm] = useState(false);
    const formMethods = useForm();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.metadata.length > 0 && props.loading && !props.metaToSend) {

            let metadata = props.metadata.filter((el) => !el.GroupName);
            let metadataTables = props.metadata.filter((el) => el.ValueType);
            metadata = metadata.filter((el) => !el.ValueType);
            let docInfo = {
                metadata,
                metadataTables,
            };
            setdocumentInfo(docInfo);
            setopenEditableForm(true)
        } else if (props.metadata.length > 0 && props.loading && props.metaToSend) {
            setdocumentInfo([]);
            props.sendAction();
        }
    }, [props.metadata]);

    useEffect(() => {
        if (Object.keys(documentInfo).length > 0 && props.loading) {
            props.setLoadFile(false);
        }
    }, [documentInfo]);

    const setNewMetadata = (formData) => {
        props.setLoadFile(true);
        const generalMetadata = documentInfo.metadata.map((meta, i) => {
            return {
                ...meta,
                Value: formData[meta.Name],
            };
        });
        let newData = [...generalMetadata];
        const nameTables = documentInfo.metadataTables.map((el) => el.Name);
        const tablesKeys = nameTables.map((nameTab, i) => {
            let aux = [];
            Object.keys(formData).forEach((keyform) => {
                if (keyform.startsWith(nameTab)) {
                    const fields = keyform.split("-");
                    aux = [
                        ...aux,
                        {
                            tableName: fields[0],
                            fieldName: fields[1],
                            order: +fields[2],
                            fieldValue: formData[keyform],
                        },
                    ];
                }
            });
            return aux;
        });

        const groups = tablesKeys.map((tab) => {
            let aux = [];
            tab.forEach((t) => {
                aux[t.order] = [...(aux[t.order] ?? []), t];
            });
            return aux;
        });

        groups.forEach((row, i) => {
            const rows = row.map((r) => {
                return r.map((el) => ({ TextValue: el.fieldValue, Confidence: 1.0 }));
            });
            const table = {
                Columns: JSON.parse(documentInfo.metadataTables[i].Value).Columns,
                Rows: rows,
            };
            newData = [
                ...newData,
                {
                    ...documentInfo.metadataTables[i],
                    Value: JSON.stringify(table),
                },
            ];
        });

        setopenEditableForm(false);
        props.setMetaToSend(true);
        props.setMetadatos(newData);
    };

    if (openEditableForm) {
        return (
            <FormProvider {...formMethods}>
                <EditableModalForm
                    openEditableForm={openEditableForm}
                    setopenEditableForm={setopenEditableForm}
                    metadata={documentInfo.metadata}
                    fieldsFormat={props.fields}
                    metadataTables={documentInfo.metadataTables}
                    doc={props.doc}
                    docExt={props.docExt}
                    setNewMetadata={setNewMetadata}
                    folioId={props.folioId}
                    docId={props.docId}
                    closeAction={props.closeAction}
                />
            </FormProvider>
        );
    }

    return (
        <Dialog
            fullScreen
            open={props.open}
            onClose={!props.open}
            className={localStyles.container}
        >
            <CustomLoadingOverlay
                active={props.loading}
                text={t("general_loading_message")}
            />

            <div className={gridContainer.centerColumn}>
                {Object.keys(documentInfo).length > 0 && props.ocrInfo.ocr != 0 ? (
                    <div className={localStyles.modal}>
                        {openEditableForm ?
                            <FormProvider {...formMethods}>
                                <EditableModalForm
                                    openEditableForm={openEditableForm}
                                    setopenEditableForm={setopenEditableForm}
                                    metadata={documentInfo.metadata}
                                    metadataTables={documentInfo.metadataTables}
                                    fieldsFormat={props.fields}
                                    doc={props.doc}
                                    docExt={props.docExt}
                                    setNewMetadata={setNewMetadata}
                                    folioId={props.folioId}
                                    docId={props.docId}
                                    closeAction={props.closeAction}
                                />
                            </FormProvider>
                            :
                            null
                        }
                    </div>
                ) : null}
            </div>
        </Dialog>
    );
};

const EditableModalForm = (props) => {
    const theme = useTheme()
    const classes = useClasses();
    const localStyles = useClasses();
    const { control, handleSubmit, watch, setValue, formState, register, setError } = useForm({ mode: 'onBlur' });
    const dispatch = useDispatch()

    const [listMetadata, setListMetadata] = useState([]);
    const [loadPostalCode, setLoadPostalCode] = useState(false)
    const [listColoniaCurrent, setListColoniaCurrent] = useState([]);
    const [loadInitFind, setLoadInitFind] = useState(false)

    const dataSearchCP = useSelector(state => state.SearchData)

    useEffect(() => {
        setListMetadata([])
        if (props.metadata?.length > 0 && props.fieldsFormat?.length > 0) {
            let newData = [];

            props.metadata?.forEach((data) => {
                let findProps = props.fieldsFormat?.find(
                    (field) => data.Name === field.FieldQuestionName
                );
                if (findProps !== undefined && findProps !== null) {
                    if (!findProps?.Visible) {
                        return null;
                    }
                    let nameLabel = findProps.FieldName;
                    newData.push({
                        ...data,
                        MaxLength: findProps.maxLength === undefined || findProps.maxLength === null ? 200 : findProps.maxLength,
                        Label: nameLabel.length > 0 ? nameLabel?.toUpperCase() : data.Name?.toUpperCase(),
                        RegexValidation: findProps?.RegexValidation
                    })
                } else {
                    newData.push({
                        ...data,
                        MaxLength: 200,
                        Label: data.Name?.toUpperCase(),
                        RegexValidation: findProps?.RegexValidation
                    })
                }
            });
            setListMetadata(newData)
        } else {
            let newData = props.metadata.map((data) => {
                return {
                    ...data,
                    MaxLength: 200,
                    Label: data.Name?.toUpperCase(),
                    RegexValidation: null
                };
            });
            setListMetadata(newData)
        }
    }, [props.fieldsFormat, props.metadata]);

    useEffect(() => {
        if (listMetadata?.length > 0) {
            listMetadata?.forEach(item => {
                setValue(item.Name, item.Value)
            })
        }
    }, [listMetadata])

    useEffect(() => {
        if (dataSearchCP.items !== undefined && loadPostalCode) {
            setLoadPostalCode(false)
            let listColonia = dataSearchCP.items?.Body?.find(item => item.Question === "Colonia"
                && item.QuestionGroup === "Comprobante de domicilio")?.Values
                
            setListColoniaCurrent(listColonia)

            let listCiudad = dataSearchCP.items?.Body?.find(item => item.Question === "Ciudad"
                && item.QuestionGroup === "Comprobante de domicilio")?.Values[0]

            let listMunicipio = dataSearchCP.items?.Body?.find(item => item.Question === "Municipio"
                && item.QuestionGroup === "Comprobante de domicilio")?.Values[0]

            let listEstado = dataSearchCP.items?.Body?.find(item => item.Question === "Estado"
                && item.QuestionGroup === "Comprobante de domicilio")?.Values[0]

            setValue("Ciudad", listCiudad);
            setValue("Municipio", listMunicipio);
            setValue("Estado", listEstado);
            setValue("Colonia", listColonia[0]);
            console.log("Colonia: ", listColonia[0])
        } else if (dataSearchCP.error !== undefined && loadPostalCode) {
            setLoadPostalCode(false)
            console.log("Error consulta: ", dataSearchCP.error)
        }
    }, [dataSearchCP])

    useEffect(() => {
        if (listMetadata !== null && listMetadata != "" && listMetadata?.length > 4 && loadInitFind == false) {
            let cpFind = listMetadata?.find(item => item.Name === "Código Postal")?.Value;
            GetFindPostalCode(cpFind, "Código Postal", "")
            setLoadInitFind(true)
        }
    }, [listMetadata])

    let GetFindPostalCode = (code, name, group) => {
        if (code !== null && code !== "" && code?.length > 4) {
            setLoadPostalCode(true)
            let data = {
                File_Id: null,
                Document_Id: null,
                DocumentType_Id: props.docId,
                QuestionGroup: "Comprobante de domicilio",
                Question: `${name}`,
                Value: `${code}`
            }
            dispatch(executeSearchActions.GetByCodigoPostal(data))
        }
    }

    return (
        <Dialog
            open={props.openEditableForm}
            onClose={(event, reason) => {
                console.log("(event, reason)*****: ", event, reason)
                if (reason && reason == "backdropClick")
                    return;
                props.setopenEditableForm(false)
                props.closeAction()
            }}
            fullWidth
            PaperProps={{
                style: {
                    overflow: "auto",
                    minHeight: "80%",
                },
            }}
            className={classes.overFlowModal}
        >
            <ContainerVisorCustom>
                <Box sx={{ width: '100%', padding: '0.5em' }}>
                    {props.docExt === ".jpg" ||
                        props.docExt === ".jpeg" ||
                        props.docExt === ".png" ?
                        <img alt="" src={props.doc} style={{ width: "100%" }} /> :
                        navigator.userAgent.match(/iPhone/i) ?
                            <PdfContainerIOS>
                                <object id="containerIOS" data={props.doc} type='application/pdf' />
                            </PdfContainerIOS>
                            : navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i) ?
                                <VisorPDF pdfUrl={props.doc} pdfName={props.FileName ?? ""} height={"70vh"} />
                                : <object width="100%" height="100%" data={props.doc} type='application/pdf' />
                    }
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: '1em',
                        width: '100%'
                    }}
                >
                    <Typography variant="h6">Edición de datos</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                        {listMetadata?.length > 0
                            ? listMetadata.map((doc, i) => <>
                                {console.log("Metadadata: ITEM: ******:  ", doc)}
                                <Controller
                                    control={control}
                                    name={doc.Name}
                                    rules={{ required: true }}
                                    defaultValue={doc.Value}
                                    render={({ field: { ref, ...restFields } }) => (
                                        doc.Name === "Código Postal" ?
                                            <TextField
                                                {...restFields}
                                                {...register(doc.Name, {
                                                    required: "Requerido",
                                                    minLength: { value: 5, message: 'Código Postal. ej. 54960' }
                                                })}
                                                required={true}
                                                error={!!formState.errors[doc.Name]}
                                                helperText={formState.errors[doc.Name]?.message}
                                                inputRef={ref}
                                                fullWidth
                                                autoComplete='new-password'
                                                variant="outlined"
                                                className={`${localStyles.inputs}`}
                                                name={doc.Name}
                                                label={doc.Label}
                                                onInput={e => {
                                                    console.log("event12 ", doc.Name, e.target.value)
                                                    let z1 = /^[0-9]*$/;
                                                    if (!z1.test(e.target.value)) {
                                                        console.log("Entro en el control", localStorage.getItem(doc.Name), !z1.test(e.target.value));
                                                        setValue(doc.Name, localStorage.getItem(doc.Name));
                                                        setError(doc.Name, "Por favor ingrese solo caracteres numéricos")
                                                    } else {
                                                        GetFindPostalCode(e.target.value, doc.Name, doc.GroupName)
                                                        setValue(doc.Name, e.target.value)
                                                        localStorage.setItem(doc.Name, e.target.value);
                                                    }
                                                }}
                                                InputProps={{
                                                    inputProps: {
                                                        maxLength: doc.MaxLength,
                                                        style: { textTransform: "uppercase" },
                                                        outline: 'none',
                                                        autoComplete: 'new-password',
                                                        form: {
                                                            autoComplete: 'off',
                                                        }
                                                    }
                                                }}
                                            />
                                            : doc.Name === "Teléfono" ?
                                                <TextField
                                                    {...restFields}
                                                    {...register(doc.Name, {
                                                        required: "Requerido",
                                                        validate: (value) => {
                                                            console.log("value phone: ", value)
                                                            return (
                                                                new RegExp(doc.RegexValidation).test(value) ||
                                                                `${doc.Name} dato inválido. Número de teléfono a 10 digitos. ej. 5567883730`
                                                            );
                                                        },
                                                        minLength: {
                                                            value: 10,
                                                            message: "Número de teléfono a 10 digitos. ej. 5567883730",
                                                        },
                                                    })}
                                                    error={!!formState.errors[doc.Name]}
                                                    helperText={formState.errors[doc.Name]?.message}
                                                    placeholder={doc.Label}
                                                    inputRef={ref}
                                                    fullWidth
                                                    className={`${localStyles.inputs}`}
                                                    label={doc.Label}
                                                    name={doc.Name}
                                                    variant="outlined"
                                                    required={true}
                                                    autoComplete='new-password'
                                                    InputProps={{
                                                        inputProps: {
                                                            maxLength: doc.MaxLength,
                                                            style: { textTransform: "uppercase" },
                                                            outline: 'none',
                                                            autoComplete: 'new-password',
                                                            form: {
                                                                autoComplete: 'off',
                                                            }
                                                        }
                                                    }}
                                                />
                                                : doc.Name === "Colonia"
                                                    && listColoniaCurrent?.length > 1 ?
                                                    <TextField
                                                        {...restFields}
                                                        {...register(doc.Name, {
                                                            required: "Requerido",
                                                            setValueAs: (value) => value?.toUpperCase()
                                                        })}
                                                        name={doc.Name}
                                                        required={true}
                                                        error={!!formState.errors[doc.Name]}
                                                        helperText={formState.errors[doc.Name]?.message}
                                                        inputRef={ref}
                                                        fullWidth
                                                        variant="outlined"
                                                        className={`${localStyles.inputs}`}
                                                        label={doc.Label}
                                                        select
                                                        InputProps={{
                                                            inputProps: {
                                                                maxLength: doc.MaxLength,
                                                                style: { textTransform: "uppercase" },
                                                                outline: 'none',
                                                                autoComplete: 'new-password',
                                                                form: {
                                                                    autoComplete: 'off',
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {listColoniaCurrent?.map(i => {
                                                            return <MenuItem key={i} value={i}>
                                                                {i}
                                                            </MenuItem>
                                                        }
                                                        )}
                                                    </TextField>
                                                    :
                                                    <TextField
                                                        {...restFields}
                                                        {...register(doc.Name, {
                                                            required: "Requerido",
                                                            setValueAs: (value) => value?.toUpperCase()
                                                        })}
                                                        error={!!formState.errors[doc.Name]}
                                                        helperText={formState.errors[doc.Name]?.message}
                                                        placeholder={doc.Label}
                                                        inputRef={ref}
                                                        fullWidth
                                                        className={`${localStyles.inputs}`}
                                                        label={doc.Label}
                                                        name={doc.Name}
                                                        variant="outlined"
                                                        required={true}
                                                        autoComplete='new-password'
                                                        InputProps={{
                                                            inputProps: {
                                                                maxLength: doc.MaxLength,
                                                                style: { textTransform: "uppercase" },
                                                                outline: 'none',
                                                                autoComplete: 'new-password',
                                                                form: {
                                                                    autoComplete: 'off',
                                                                }
                                                            }
                                                        }}
                                                    />
                                    )}
                                />
                            </>) : null}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 15, marginBottom: 48 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            style={{
                                maxWidth: 300,
                                margin: "20px auto 0 auto",
                                display: "block",
                                height: '48px',
                                borderRadius: '8px',
                                backgroundColor: theme.palette.primary.main,
                                textTransform: 'capitalize',
                                color: "#FFF",
                            }}
                            onClick={handleSubmit(props.setNewMetadata)}
                        >
                            Aceptar
                        </Button>
                        <Button
                            fullWidth
                            variant="outlined"
                            style={{
                                maxWidth: 300,
                                margin: "10px auto",
                                display: "block",
                                height: '48px',
                                borderRadius: '8px',
                                borderColor: theme.palette.primary.main,
                                textTransform: 'capitalize',
                                color: theme.palette.primary.main,
                            }}
                            onClick={() => {
                                props.setopenEditableForm(false)
                                props.closeAction()
                            }}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </ContainerVisorCustom>
        </Dialog >
    );
};

const useClasses = makeStyles((theme) => ({
    modalEditable: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        width: "100%",
        minHeight: "80vh",
    },
    inputText: {
        marginTop: 15,
        "& .MuiOutlinedInput-input": {
            padding: "10px 15px",
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: 8,
            borderColor: theme.palette.primary.main,
            fontSize: 14,
            "& fieldset legend span": {
                display: "none",
            },
        },
        "& .MuiInputLabel-root": {
            color: "#000",
            position: "relative",
            transform: "none",
            fontSize: 14,
            marginBottom: 8,
            marginTop: 5,
            display: "flex",
            alignItems: "center",
            pointerEvents: "auto",
            fontWeight: 500,
        },
    },
    outlinedText: {
        marginTop: 15,
        borderRadius: 8,
        fontSize: 14,
        "& .MuiOutlinedInput-input": {
            height: 25,
            padding: "10px 15px",
        },
    },
    accSummary: {
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
    },
    tableHead: {
        backgroundColor: "#AAA",
        "& th": {
            color: "#FFF",
        },
    },
    errorInput: {
        "& .MuiFormLabel-root": {
            color: theme.palette.primary.color,
        },
    },
    indicator: {
        color: theme.palette.primary.main,
    },
    capitalize: {
        textTransform: "capitalize !important",
    },
    overFlowModal: {
        overflow: "hidden !important",
        "& .MuiDialog-paperWidthSm": {
            minWidth: '800px !important',
            ['@media only screen and (max-width: 800px)']: {
                minWidth: '320px !important',
            },
        },
        "&MuiPaper-root MuiDialog-paper MuiDialog-paperScrollPaper": {
            overflow: "hidden !important",
        },
    },
}));
