import { useTheme } from '@emotion/react';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { uploadDocumentWithSection } from 'api/extraction/extractionRequests';
import { AlertModal } from 'components/common/Modals/Views/AlertModal';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { executeSearchActions, fileActions } from 'store/actions';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

const useClasses = makeStyles((theme) => ({
  modalEditable: {
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    minHeight: '80vh',
  },
  inputText: {
    marginTop: 15,
    '& .MuiOutlinedInput-input': {
      padding: '10px 15px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      borderColor: theme.palette.primary.main,
      fontSize: 14,
      '& fieldset legend span': {
        display: 'none',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#000',
      position: 'relative',
      transform: 'none',
      fontSize: 14,
      marginBottom: 8,
      marginTop: 5,
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'auto',
      fontWeight: 500,
    },
  },
  outlinedText: {
    marginTop: 15,
    borderRadius: 8,
    fontSize: 14,
    '& .MuiOutlinedInput-input': {
      height: 25,
      padding: '10px 15px',
    },
  },
  accSummary: {
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  tableHead: {
    backgroundColor: '#AAA',
    '& th': {
      color: '#FFF',
    },
  },
  errorInput: {
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.color,
    },
  },
  indicator: {
    color: theme.palette.primary.main,
  },
  capitalize: {
    textTransform: 'capitalize !important',
  },
  overFlowModal: {
    overflow: 'hidden !important',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '800px !important',
    },
    '&MuiPaper-root MuiDialog-paper MuiDialog-paperScrollPaper': {
      overflow: 'hidden !important',
    },
  },
}));

export const OCRDocument = ({ documentB64, documentSpec }) => {
  const workFlowInstanceId = sessionStorage.getItem('WorkFlowInstanceId');
  const { trigger: triggerUploadDocumentWithSection } = useSWRMutation(
    'DocumentUploadWithSection',
    (url, payload) => uploadDocumentWithSection(payload),
    {
      populateCache: true,
      revalidate: false,
    },
  );
  const { id: fileId } = useParams();
  const [loading, setLoading] = useState(false);
  const [documentInfo, setdocumentInfo] = useState(null);
  const formMethods = useForm();
  const { data: metadataOCR } = useSWR('OcrExtraction');
  const dispatch = useDispatch();

  useEffect(() => {
    if (metadataOCR.Metadatos.length > 0) {
      let metadata = metadataOCR.Metadatos;
      let metadataTables = metadata.filter((el) => el.ValueType);
      metadata = metadata.filter((el) => !el.ValueType);
      let docInfo = {
        metadata,
        metadataTables,
      };
      setdocumentInfo(docInfo);
    }
  }, [metadataOCR]);

  const setNewMetadata = (formData) => {
    const generalMetadata = documentInfo.metadata.map((meta, i) => {
      return {
        ...meta,
        Value: formData[meta.Name],
      };
    });
    let newData = [...generalMetadata];
    const nameTables = documentInfo.metadataTables.map((el) => el.Name);
    const tablesKeys = nameTables.map((nameTab, i) => {
      let aux = [];
      Object.keys(formData).forEach((keyform) => {
        if (keyform.startsWith(nameTab)) {
          const fields = keyform.split('-');
          aux = [
            ...aux,
            {
              tableName: fields[0],
              fieldName: fields[1],
              order: +fields[2],
              fieldValue: formData[keyform],
            },
          ];
        }
      });
      return aux;
    });

    const groups = tablesKeys.map((tab) => {
      let aux = [];
      tab.forEach((t) => {
        aux[t.order] = [...(aux[t.order] ?? []), t];
      });
      return aux;
    });

    groups.forEach((row, i) => {
      const rows = row.map((r) => {
        return r.map((el) => ({ TextValue: el.fieldValue, Confidence: 1.0 }));
      });
      const table = {
        Columns: JSON.parse(documentInfo.metadataTables[i].Value).Columns,
        Rows: rows,
      };
      newData = [
        ...newData,
        {
          ...documentInfo.metadataTables[i],
          Value: JSON.stringify(table),
        },
      ];
    });
    askforTransactionId(newData);
  };

  const askforTransactionId = async (currentMetadata) => {
    setLoading(true);
    const requestUpload = {
      UploadSectionRequestData: {
        B64Content: documentB64.fileb64,
        FileId: fileId,
        Actor_Id: null,
        SkipActor: null,
        SectionId: documentSpec.SectionId,
        Extension: `.${documentB64.extension}` || '.jpg',
        Skip: false,
        SkipReason: null,
        Metadata: currentMetadata?.length > 0 ? currentMetadata : null,
        ContinueWorkFlow: true,
        Geolocation: null,
        DeferLoad: false,
        OmitirMergeVideo: null,
        OmitirRemovebg: null,
        AsignarEstatusCarga: null,
      },
      File_id: fileId,
      Carga: {
        Referencia: fileId.toString(),
        TransactionId: null,
        EsBolqueFin: true,
        TipoDocumento: documentSpec.Name,
        Seccion: 'Documento',
        Imagenes: [
          {
            B64: documentB64.fileb64,
            Extension: `.${documentB64.extension}` ? `.${documentB64.extension}` : '.jpg',
          },
        ],
        Metadatos: null,
      },
    };

    try {
      await triggerUploadDocumentWithSection(requestUpload);
      dispatch(fileActions.GetFileInfo({ FileId: fileId, WorkFlowInstanceId: workFlowInstanceId }));
    } catch (err) {
      AlertModal('Error', err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  if (!documentInfo) return null;

  return (
    <>
      <CustomLoadingOverlay active={loading} />
      <FormProvider {...formMethods}>
        <EditableModalForm
          metadata={documentInfo.metadata}
          metadataTables={documentInfo.metadataTables}
          setNewMetadata={setNewMetadata}
          folioId={fileId}
          docId={documentSpec?.DocumentType_Id}
        />
      </FormProvider>
    </>
  );
};

const EditableModalForm = (props) => {
  const theme = useTheme();

  const localStyles = useClasses();
  const { control, handleSubmit, setValue, formState, register, setError } = useForm({
    mode: 'onBlur',
  });
  const dispatch = useDispatch();

  const [listMetadata, setListMetadata] = useState([]);
  const [loadPostalCode, setLoadPostalCode] = useState(false);
  const [listColoniaCurrent, setListColoniaCurrent] = useState([]);
  const [loadInitFind, setLoadInitFind] = useState(false);
  const dataSearchCP = useSelector((state) => state.SearchData);

  useEffect(() => {
    setListMetadata([]);

    let newData = props.metadata.map((data) => {
      return {
        ...data,
        MaxLength: 200,
        Label: data.Name?.toUpperCase(),
        RegexValidation: null,
      };
    });
    setListMetadata(newData);
  }, [props.metadata]);

  useEffect(() => {
    if (listMetadata?.length > 0) {
      listMetadata?.forEach((item) => {
        setValue(item.Name, item.Value);
      });
    }
  }, [listMetadata]);

  useEffect(() => {
    if (dataSearchCP.items !== undefined && loadPostalCode) {
      setLoadPostalCode(false);
      let listColonia = dataSearchCP.items?.Body?.find(
        (item) => item.Question === 'Colonia' && item.QuestionGroup === 'Comprobante de domicilio',
      )?.Values;

      setListColoniaCurrent(listColonia);

      let listCiudad = dataSearchCP.items?.Body?.find(
        (item) => item.Question === 'Ciudad' && item.QuestionGroup === 'Comprobante de domicilio',
      )?.Values[0];

      let listMunicipio = dataSearchCP.items?.Body?.find(
        (item) =>
          item.Question === 'Municipio' && item.QuestionGroup === 'Comprobante de domicilio',
      )?.Values[0];

      let listEstado = dataSearchCP.items?.Body?.find(
        (item) => item.Question === 'Estado' && item.QuestionGroup === 'Comprobante de domicilio',
      )?.Values[0];

      setValue('Ciudad', listCiudad);
      setValue('Municipio', listMunicipio);
      setValue('Estado', listEstado);
      setValue('Colonia', listColonia[0]);
    } else if (dataSearchCP.error !== undefined && loadPostalCode) {
      setLoadPostalCode(false);
    }
  }, [dataSearchCP]);

  useEffect(() => {
    if (listMetadata && listMetadata?.length > 4 && !loadInitFind) {
      let cpFind = listMetadata?.find((item) => item.Name === 'Código Postal')?.Value;
      GetFindPostalCode(cpFind, 'Código Postal', '');
      setLoadInitFind(true);
    }
  }, [listMetadata]);

  let GetFindPostalCode = (code, name, group) => {
    if (code !== null && code !== '' && code?.length > 4) {
      setLoadPostalCode(true);
      let data = {
        File_Id: null,
        Document_Id: null,
        DocumentType_Id: props.docId,
        QuestionGroup: 'Comprobante de domicilio',
        Question: `${name}`,
        Value: `${code}`,
      };
      dispatch(executeSearchActions.GetByCodigoPostal(data));
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          width: '100%',
        }}
      >
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            fullWidth
            variant="contained"
            style={{
              maxWidth: 300,
              display: 'block',
              height: '48px',
              backgroundColor: theme.palette.primary.main,
              textTransform: 'capitalize',
              color: '#FFF',
            }}
            onClick={handleSubmit(props.setNewMetadata)}
          >
            Aceptar
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          {listMetadata?.length > 0
            ? listMetadata.map((doc, i) => (
                <>
                  <Controller
                    control={control}
                    name={doc.Name}
                    rules={{ required: true }}
                    defaultValue={doc.Value}
                    render={({ field: { ref, ...restFields } }) =>
                      doc.Name === 'Código Postal' ? (
                        <TextField
                          {...restFields}
                          {...register(doc.Name, {
                            required: 'Requerido',
                            minLength: {
                              value: 5,
                              message: 'Código Postal. ej. 54960',
                            },
                          })}
                          required={true}
                          error={!!formState.errors[doc.Name]}
                          helperText={formState.errors[doc.Name]?.message}
                          inputRef={ref}
                          fullWidth
                          autoComplete="new-password"
                          variant="outlined"
                          className={`${localStyles.inputs}`}
                          name={doc.Name}
                          label={doc.Label}
                          sx={{ my: 2 }}
                          onInput={(e) => {
                            let z1 = /^[0-9]*$/;
                            if (!z1.test(e.target.value)) {
                              setValue(doc.Name, localStorage.getItem(doc.Name));
                              setError(doc.Name, 'Por favor ingrese solo caracteres numéricos');
                            } else {
                              GetFindPostalCode(e.target.value, doc.Name, doc.GroupName);
                              setValue(doc.Name, e.target.value);
                              localStorage.setItem(doc.Name, e.target.value);
                            }
                          }}
                          InputProps={{
                            inputProps: {
                              maxLength: doc.MaxLength,
                              style: { textTransform: 'uppercase' },
                              outline: 'none',
                              autoComplete: 'new-password',
                              form: {
                                autoComplete: 'off',
                              },
                            },
                          }}
                        />
                      ) : doc.Name === 'Teléfono' ? (
                        <TextField
                          {...restFields}
                          {...register(doc.Name, {
                            required: 'Requerido',
                            validate: (value) => {
                              if (!doc.RegexValidation) return;
                              return (
                                new RegExp(doc.RegexValidation).test(value) ||
                                `${doc.Name} dato inválido. Número de teléfono a 10 digitos. ej. 5567883730`
                              );
                            },
                            minLength: {
                              value: 10,
                              message: 'Número de teléfono a 10 digitos. ej. 5567883730',
                            },
                          })}
                          error={!!formState.errors[doc.Name]}
                          helperText={formState.errors[doc.Name]?.message}
                          placeholder={doc.Label}
                          inputRef={ref}
                          fullWidth
                          className={`${localStyles.inputs}`}
                          label={doc.Label}
                          name={doc.Name}
                          variant="outlined"
                          required={true}
                          autoComplete="new-password"
                          sx={{ my: 2 }}
                          InputProps={{
                            inputProps: {
                              maxLength: doc.MaxLength,
                              style: { textTransform: 'uppercase' },
                              outline: 'none',
                              autoComplete: 'new-password',
                              form: {
                                autoComplete: 'off',
                              },
                            },
                          }}
                        />
                      ) : doc.Name === 'Colonia' && listColoniaCurrent?.length > 1 ? (
                        <TextField
                          {...restFields}
                          {...register(doc.Name, {
                            required: 'Requerido',
                            setValueAs: (value) => value?.toUpperCase(),
                          })}
                          name={doc.Name}
                          required={true}
                          error={!!formState.errors[doc.Name]}
                          helperText={formState.errors[doc.Name]?.message}
                          inputRef={ref}
                          fullWidth
                          variant="outlined"
                          className={`${localStyles.inputs}`}
                          label={doc.Label}
                          sx={{ my: 2 }}
                          select
                          InputProps={{
                            inputProps: {
                              maxLength: doc.MaxLength,
                              style: { textTransform: 'uppercase' },
                              outline: 'none',
                              autoComplete: 'new-password',
                              form: {
                                autoComplete: 'off',
                              },
                            },
                          }}
                        >
                          {listColoniaCurrent?.map((i) => {
                            return (
                              <MenuItem key={i} value={i}>
                                {i}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      ) : (
                        <TextField
                          {...restFields}
                          {...register(doc.Name, {
                            required: 'Requerido',
                            setValueAs: (value) => value?.toUpperCase(),
                          })}
                          error={!!formState.errors[doc.Name]}
                          helperText={formState.errors[doc.Name]?.message}
                          placeholder={doc.Label}
                          inputRef={ref}
                          fullWidth
                          className={`${localStyles.inputs}`}
                          label={doc.Label}
                          name={doc.Name}
                          variant="outlined"
                          required={true}
                          sx={{ my: 2 }}
                          autoComplete="new-password"
                          InputProps={{
                            inputProps: {
                              maxLength: doc.MaxLength,
                              style: { textTransform: 'uppercase' },
                              outline: 'none',
                              autoComplete: 'new-password',
                              form: {
                                autoComplete: 'off',
                              },
                            },
                          }}
                        />
                      )
                    }
                  />
                </>
              ))
            : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        ></Box>
      </Box>
    </>
  );
};
