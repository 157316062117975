import {
  Box,
  Button as Btn,
  ButtonGroup,
  Grid,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FullCameraCapture } from 'components/Camera/FullCameraCapture';
import UploadSlot from 'components/common/UploadSlot';
import { Icon } from '@iconify/react';
import { useValidateDocument } from 'hooks/document/useValidateDocument';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import useSWR from 'swr';
import { SendEmailModal } from 'components/common/Modals/Views/SendEmailModal';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const BtnCustom = styled(Btn)(({ theme }) => ({
  borderRadius: '0px !important',
}));

const DocumentMultiLoader = ({ handleMode, keepRevalidating, handleSetDocumentB64 }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [initCamera, setInitCamera] = useState(false);
  const { data: actionIdData } = useSWR('CreateActionId');
  const { blobUrl, linkCode, Description, isLoadingActionId, isLoadingDocumentStructure } =
    useValidateDocument(actionIdData?.Result, keepRevalidating ,handleMode);
  const { enqueueSnackbar } = useSnackbar();
  const isLoading = isLoadingActionId || isLoadingDocumentStructure;

  const handleCamera = () => setInitCamera((prevValue) => !prevValue);

  const handleCloseModal = () => setOpen(false);

  const handleCopyClipboard = () => {
    navigator.clipboard.writeText(linkCode);
    enqueueSnackbar('Vínculo copiado', { variant: 'success' });
  };

  const handleCaptureUploadDocument = (fileb64) => {
    setInitCamera(false);
    handleSetDocumentB64(fileb64);
    handleMode('DOCUMENT_METADATA');
  };

  if (isLoading) return <CustomLoadingOverlay active={isLoading} />;

  return (
    <Stack sx={{ height: '100%' }}>
      <Grid
        container
        spacing={4}
        sx={{ height: '100%', marginTop: '0px', marginLeft: '0px', width: '100%' }}
      >
        <Grid item xs={6}>
          <Box display="flex" alignItems="center" gap={'1rem'}>
            <IconButton onClick={() => handleMode( 'DEFAULT_DOCUMENT_VISUALIZATION' )}>
              <Icon icon={'mdi:arrow-back'} />
            </IconButton>
            <Typography variant="h4">{Description}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup variant="outlined" aria-label="Basic button group" color={'borderGray'} sx={{maxHeight:'2.5rem'}}>
            <Tooltip title={t('doc_multiloader_send_email')} placement="top">
              <BtnCustom
                variant={'outlined'}
                sx={{ width: '100%', padding: '8px' }}
                onClick={() => setOpen(true)}
              >
                <Icon icon={'material-symbols-light:mail-outline'} fontSize={'2rem'} color={'black'} />
              </BtnCustom>
            </Tooltip>
            <Tooltip title={t('doc_multiloader_copy_link')} placement="top">
              <BtnCustom
                variant={'outlined'}
                sx={{ width: '100%', padding: '8px' }}
                onClick={handleCopyClipboard}
              >
                <Icon icon={'ph:link-light'} fontSize={'1.5rem'} color={'black'} />
              </BtnCustom>
            </Tooltip>
            <Tooltip title={t('doc_multiloader_capture_doc')} placement="top">
              <BtnCustom
                variant={'outlined'}
                sx={{ width: '100%', padding: '8px' }}
                onClick={handleCamera}
              >
                <Icon icon={'ph:camera-light'} fontSize={'1.5rem'} color={'black'} />
              </BtnCustom>
            </Tooltip>
          </ButtonGroup>
        </Grid>

        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Typography variant="span" sx={{ paddingBottom: '2rem' }}>
            {t('documentation_upload_doc_instructions')}
          </Typography>
          <Stack sx={{ height: '50%' }}>
            <UploadSlot
              allowedExtensions={['pdf', 'png', 'jpg']}
              onSendData={(fileb64, extension) =>
                handleCaptureUploadDocument({ fileb64, extension })
              }
            />
          </Stack>
        </Grid>
        <Grid xs={1} sx={{ display: 'grid', placeItems: 'center', alignItems: 'start' }}>
          <div style={{ height: '80%', width: '1px', backgroundColor: '#929292' }}> </div>
        </Grid>
        <Grid item xs={5} sx={{display: "flex", flexDirection: 'column', justifyContent: 'start', alignItems: 'center'}}>
          <Typography variant="span">
            {t('documentation_upload_doc_mobile_instructions_1')}
            <br />
            <br />
            {t('documentation_upload_doc_mobile_instructions_2')}
          </Typography>
          <img
            src={blobUrl}
            alt=""
            style={{ width: '13rem', height: '13rem', objectFit: 'contain' }}
          />
        </Grid>
      </Grid>
      <FullCameraCapture
        open={initCamera}
        onClose={() => {
          setInitCamera(false);
        }}
        onSendData={(fileb64, extension) => {
          handleCaptureUploadDocument({ fileb64, extension });
        }}
      />
      <SendEmailModal open={open} handleClose={handleCloseModal} handleMode={handleMode} />
    </Stack>
  );
};
export default DocumentMultiLoader;
