import { genericConstants } from '../constants/GenericConstants';

const initialState = {
  lang: process.env.REACT_APP_LANGUAGE,
  loading: false,
  error: null,
  items: null,
  CaptureData: [],
};

export const IndentificationCaptureData = (state = initialState, action) => {
  if (action.type === genericConstants.ADD_IDENTIFICATION_DATA_SUCCESS) {
    return {
      ...state,
      CaptureData: action.payload,
    };
  }
  return {
    ...state,
  };
};

export const SetDefualtLanguage = (state = initialState, action) => {
  if (action.type === genericConstants.SET_DEFAULT_LANGUAGE) {
    return {
      ...state,
      lang: action.payload,
    };
  } else {
    return state;
  }
};

export const SetAvatar = (state = initialState, action) => {
  if (action.type === genericConstants.SET_AVATAR) {
    return {
      ...state,
      avatar: action.payload,
    };
  } else {
    return state;
  }
};


export const SetName = (state = initialState, action) => {
  if (action.type === genericConstants.SET_NAME) {
    return {
      ...state,
      name: action.payload,
    };
  } else {
    return state;
  }
};
