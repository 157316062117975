import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// import { ReactComponent as RequiredDocument } from 'assets/DOC/Icons/RequiredDocuments.svg';
import RequiredDocument from "assets/Metlife/Icons/RequiredDocuments.png";
import { MobileUploadLanguageModal } from "components/common/Modals/Views/MobileUploadLanguageModal";
import { useState } from "react";
import { UseImage } from "components";

export const MobileUploaderRequiredDocuments = ({
  handleContinue,
  requiredDocument,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(true);

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div>
        <UseImage src={RequiredDocument} />
      </div>
      <Box>
        <Typography variant="h6" sx={{ paddingBottom: "1.5rem" }}>
          {t("mobile_uploader_required_docs")}
        </Typography>
        <Typography variant="h6" sx={{ paddingBottom: "1.5rem" }}>
          {requiredDocument}
        </Typography>
        {!process.env.REACT_APP_ENVIRONMENT === "BRADEV" ? (
          <MobileUploadLanguageModal
            open={showModal}
            onClose={handleCloseModal}
          />
        ) : null}
      </Box>

      <Button
        variant="contained"
        sx={{ width: "200px" }}
        onClick={() => handleContinue("SELECTOR")}
      >
        {t("general_continue")}
      </Button>
    </>
  );
};
export default MobileUploaderRequiredDocuments;
