import { Fragment, useEffect } from "react";

import { Box, Button } from "@mui/material";
import { InputField } from "components";
import { InputTypeMappings } from "mappings/InputTypeMappings";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const DynamicFormFlex = ({
  questionsGroups = [],
  onBlur,
  handleSave,
  TextButton,
  displayProps,
  ClearFields,
  HandleCleanFields,
}) => {
  const { control, register, formState, handleSubmit, reset } =
    useFormContext() || {};
  const { t } = useTranslation();

  useEffect(() => {
    if (ClearFields) {
      reset();
      HandleCleanFields();
    }
  }, [ClearFields]);

  return (
    <Box
      sx={{
        p: 1,
        display: displayProps,
        justifyContent: "center",
        gap: "1rem",
        width: "100%",
      }}
    >
      {questionsGroups.map((questionGroup) => {
        return questionGroup.Questions.map((question) => {
          const InputType =
            InputTypeMappings[question.QuestionType?.toUpperCase()];
          return (
            <Fragment key={question.Id}>
              <InputField
                inputType={InputType}
                control={control}
                register={register}
                name={`${question.Id}`}
                label={question.Description}
                validations={{ required: question.Required }}
                error={!!formState.errors[`${question.Id}`]}
                helperText={formState.errors[`${question.Id}`]?.message}
                required={question.Required}
                radioValues={question.Options}
                optionsList={question.Options}
                maxDate={InputType === "date" ? new Date() : null}
                disabled={question.Disabled}
                onSelect={onBlur}
              />
            </Fragment>
          );
        });
      })}
      {handleSave && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={handleSubmit(handleSave)}
            sx={{
              width: 180,
              height: 45,
              mt: 1,
              alignSelf: "end",
            }}
          >
            {TextButton || t("general_save")}
          </Button>
        </Box>
      )}
    </Box>
  );
};
