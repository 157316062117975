import { Box, IconButton, Typography } from '@mui/material';
import { getDocumentQuestions, uploadSectionGeneral } from 'api/document/documentRequests';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import { DynamicForm } from 'components/LEGOS/DynamicForm/DynamicForm';
import { FormProvider, useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import { useEffect, useState } from 'react';
import { dynamicFormAdapter } from 'adapters/dynamicFormAdapter';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { getOcrExtraction, uploadDocumentWithSection } from 'api/extraction/extractionRequests';
// import { useParams } from 'react-router-dom';
import { AlertModal } from 'components/common/Modals/Views/AlertModal';
import useSWR from 'swr';

const DocumentMetadata = ({
  documentB64,
  documentSpec,
  handleUploadFileSuccess,
  actionId,
  handleMode,
  isMobileView,
  stopRevalidating
}) => {
  const formMethods = useForm({
    mode: 'onBlur',
  });
  const { t } = useTranslation();
  const { data: documentStructure } = useSWR('GetDocumentalStructure');
  const [questionGroups, setQuestionGroups] = useState([]);
  const fileId = documentStructure.FileId;

  const { isMutating: loadingGetDocumentQuestions, trigger: triggerGetDocumentQuestions } =
    useSWRMutation('OcrExtraction', (url, payload) => getDocumentQuestions(payload), {
      populateCache: true,
      revalidate: false,
    });

  const { isMutating: loadingGetOcr, trigger: triggerGetOcr } = useSWRMutation(
    'OcrExtraction',
    (url, payload) => getOcrExtraction(payload),
    { populateCache: true, revalidate: false },
  );

  const { isMutating: loadingUploadWithSection, trigger: triggerUploadDocumentWithSection } =
    useSWRMutation(
      'DocumentUploadWithSection',
      (url, payload) => uploadDocumentWithSection(payload),
      { populateCache: true, revalidate: false },
    );

  const { isMutating: loadingUploadDocument, trigger: triggerUploadSectionGeneral } =
    useSWRMutation('UploadSectionGeneral', (url, payload) => uploadSectionGeneral(payload), {
      revalidate: false,
      onSuccess: handleUploadFileSuccess,
    });

  const IS_DOCUMENT_WITH_OCR = documentSpec?.Properties?.find((e) => e.Name === 'OcrCapture')?.Value === '1';
  const IS_MUTATION_IN_PROGRESS =
    loadingGetOcr ||
    loadingUploadWithSection ||
    loadingUploadDocument ||
    loadingGetDocumentQuestions;
    
  const UPLOAD_SECTION_PAYLOAD = {
    B64Content: documentB64.fileb64 || null,
    FileId: fileId,
    Actor_Id: null,
    SkipActor: null,
    SectionId: documentSpec.SectionId,
    Extension: `.${documentB64.extension}`,
    Skip: false,
    SkipReason: null,
    Metadata: null,
    ContinueWorkFlow: false,
    Geolocation: null,
    DeferLoad: false,
    ActionId: actionId,
  };

  const askforTransactionId = async (currentMetadata) => {
    const requestUpload = {
      UploadSectionRequestData: {
        B64Content: documentB64.fileb64,
        FileId: fileId,
        Actor_Id: null,
        SkipActor: null,
        SectionId: documentSpec.SectionId,
        Extension: `.${documentB64.extension}` || '.jpg',
        Skip: false,
        SkipReason: null,
        Metadata: currentMetadata?.length > 0 ? currentMetadata : null,
        ContinueWorkFlow: false,
        Geolocation: null,
        DeferLoad: false,
        OmitirMergeVideo: null,
        OmitirRemovebg: null,
        AsignarEstatusCarga: null,
      },
      File_id: fileId,
      Carga: {
        Referencia: fileId.toString(),
        TransactionId: null,
        EsBolqueFin: true,
        TipoDocumento: documentSpec.Name,
        Seccion: 'Documento',
        Imagenes: [
          {
            B64: documentB64.fileb64,
            Extension: `.${documentB64.extension}` ? `.${documentB64.extension}` : '.jpg',
          },
        ],
        Metadatos: null,
      },
    };

    const requestOCR = {
      File_id: fileId,
      DocumentType_Id: documentSpec.DocumentType_Id,
      TransactionId: null,
    };

    try {
      const response = await triggerUploadDocumentWithSection(requestUpload);
      await triggerGetOcr({ ...requestOCR, TransactionId: response?.TransactionId });
    } catch (err) {
      AlertModal('Error', err.message, 'error');
    }
  };

  const getQuestionGroups = async () => {
    const PAYLOAD = {
      File_Id: fileId,
      DocumentType_Id: documentSpec.DocumentType_Id,
    };
    try {
      const rawQuestionGroups = await triggerGetDocumentQuestions(PAYLOAD);
      if (rawQuestionGroups && rawQuestionGroups.length === 0) {
        if (IS_DOCUMENT_WITH_OCR) {
          await askforTransactionId(rawQuestionGroups);
          handleMode('DOCUMENT_OCR');
          return;
        }
        stopRevalidating();
        return await triggerUploadSectionGeneral(UPLOAD_SECTION_PAYLOAD);
      }

      return setQuestionGroups(dynamicFormAdapter({
        QuestionGroups: rawQuestionGroups
      }));
    } catch (err) {
      AlertModal('Error', err.message, 'error');
    }
  };

  const handleSaveMetadata = async (formData) => {
    if (IS_DOCUMENT_WITH_OCR) {
      await askforTransactionId(formData);
      handleMode('DOCUMENT_OCR');
      return;
    }
    const keysDocumentMetadata = Object.keys(formData);
    const documentMetadata = keysDocumentMetadata.map((el) => {
      return {
        GroupName: questionGroups?.[0].Name,
        Name: el,
        Value: formData[el],
        MetadataType: questionGroups[0].Questions.find((question) => question.Name === el)?.Code,
      };
    });
    UPLOAD_SECTION_PAYLOAD.Metadata = documentMetadata;
    stopRevalidating();
    await triggerUploadSectionGeneral(UPLOAD_SECTION_PAYLOAD);
  };

  useEffect(() => {
    getQuestionGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (IS_MUTATION_IN_PROGRESS) return <CustomLoadingOverlay active={IS_MUTATION_IN_PROGRESS} />;

  return (
    <Box sx={{ display: 'grid', gap: '2rem', width: '100%' }}>
      {
        IS_MUTATION_IN_PROGRESS ? <CustomLoadingOverlay active={IS_MUTATION_IN_PROGRESS} /> : null
      }
      <Box>
        {!isMobileView && (
          <IconButton
            onClick={() => handleMode('DOCUMENT_UPLOAD')}
          >
            <Icon icon={'mdi:arrow-back'} />{' '}
          </IconButton>
        )}
        <Typography variant={'span'}>{t('capture_document_metadata_instructions')}</Typography>
      </Box>
      <FormProvider {...formMethods}>
        <DynamicForm
          questionsGroups={questionGroups}
          handleSave={handleSaveMetadata}
          isMobileView={isMobileView}
        />
      </FormProvider>
    </Box>
  );
};

export default DocumentMetadata;
