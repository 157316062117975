import MainTable from "components/common/Table";

import { formatDateByLocale } from "utils";
import { OBTableCell, OBTableRow } from "components/common/Table/Table.styled";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { PaginationCustom } from "components/table/MainTablePagination";

export const SearchResultsTable = ({ tableData, loading, onResultClick , CurrentPage, handleChangePage}) => {
  const { t } = useTranslation();
  const [columnsDynamic, setColumnsDynamic] = useState([]);
  /* const columns = [
    {
      id: "Folio",
      label: t("search_advanced_table_header_folio"),
      minWidth: 170,
    },
    {
      id: "FullName",
      label: t("search_advanced_table_header_name"),
      minWidth: 170,
    },
    {
      id: "JobPosition",
      label: t("search_advanced_table_header_job"),
      minWidth: 170,
    },
    {
      id: "GEID",
      label: t("search_advanced_table_header_geid"),
      minWidth: 170,
    },
    {
      id: "Date",
      label: t("search_advanced_table_header_date"),
      minWidth: 170,
    },
    {
      id: "Status",
      label: t("search_advanced_table_header_status"),
      minWidth: 170,
    },
  ]; */

  useEffect(() => {
    // if (tableData.length && tableData?.Metadatos) {
    columnsMapper();
    // }
  }, [tableData]);

  const columnsMapper = () => {
    const columnsTemp = [];
    const metadata = tableData?.Data[0].Metadatos[0];
    const mappedArray = Object.keys(metadata).map((key) => ({
      key,
      value: metadata[key],
    }));
    mappedArray.map((col) => {
      columnsTemp.push({ id: col.key, label: col.key });
    });
    setColumnsDynamic(columnsTemp);
  };

  const render = (row) => {
    const metadata = tableData?.Data?.find((data) => data.WorkflowInstanceId === row.WorkflowInstanceId)?.Metadatos[0];
    const mappedArray = Object.keys(metadata).map((key) => ({
      key,
      value: metadata[key],
    }));

    return (
      <OBTableRow
        hover
        role="grid"
        loading={loading.toString()}
        onClick={() => onResultClick(row)}
      >
        {mappedArray.map((rw) => {
          // console.log('key',rw)
          if (rw.key === "StepperStatus")
            return <OBTableCell>{rw.value.Label}</OBTableCell>;
          return <OBTableCell>{rw.value}</OBTableCell>;
        })}
      </OBTableRow>
    );
  };

  /* const metadata = tableData[0].Metadatos[0]
  const mappedArray = Object.keys(metadata).map((key) => ({
    key,
    value: metadata[key],
  })); */

  return (
    <MainTable
      rows={tableData?.Data}
      rowPerPage={10}
      rowsSize={"medium"}
      columns={columnsDynamic /* columns */}
      render={render}
      loading={loading}
      isDefaultPaginator={false}
    >
      <PaginationCustom
        CurrentPage={CurrentPage}
        TotalRecords={tableData?.TotalRows}
        PageSize={10}
        OnChangePage={(pg) => {
          handleChangePage(pg);
        }}
      />
    </MainTable>
  );
};
