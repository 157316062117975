import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { fileRequests } from "../services";
import { UseImage } from "./UseImage";
import iconExcel from "../assets/icons/IconExcel.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AlertModal } from "./common/Modals/Views/AlertModal";
import { useSnackbar } from "notistack";

export default function DowloadSearch({ lastSearch }) {

  const { t } = useTranslation();
  const authInfo = useSelector((state) => state.Authentication?.items);
  const { enqueueSnackbar } = useSnackbar();
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(false);
  const lastSearchValue = lastSearch?.find(item => item.Parameter === 'Search')?.Value

 const DowloadTemplate = async () => {
    try {
      setIsDownloadDisabled(true);
      enqueueSnackbar(`${t("geid_downloading")}...`, {
        variant: "success",
        autoHideDuration: 5000,
      });
      const responseDoc = await fileRequests.DownloadFilesBySearchContent({
        Parameters: [
          {
            Parameter: "DisablePagination",
            Value: 1,
          },
          ...lastSearch,
        ],
      });
      if (responseDoc?.size === 0)
        return await AlertModal(
          "",
          t("search_results_excel_download_error"),
          "warning",
        );
      const url = window.URL.createObjectURL(new Blob([responseDoc]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${t("search_data_excel_filename")}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      await AlertModal("", t("search_results_excel_download_error"), "warning");
    } finally {
      setIsDownloadDisabled(false);
    }
  };

  return (
    <Box>
      <Tooltip title={t("download_search_download_data")} placement="top">
        <IconButton
          disabled={isDownloadDisabled || !lastSearchValue}
          sx={{
            width: "100%",
            height: 45,
            fontSize: 16,
            fontWeight: 500,
            mt: 0.5,
            maxWidth: 300,
          }}
          variant="contained"
          color="primary"
          onClick={() => DowloadTemplate()}
        >
          <UseImage src={iconExcel} alt="iconExcel" width={60} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
